<script lang="ts" setup>
import type { ApiReceptionMachineryGetById, MachineryCategories, MachineryDrives } from '~/types'

const props = defineProps<{
  receptionMachinery: ApiReceptionMachineryGetById
}>()

const { receptionMachineryVehicle, receptionMachineryPlatform, receptionMachineryMast, receptionMachineryDimension, receptionMachineryTires, receptionMachineryMotor, receptionMachineryBattery, receptionMachinerySteeringType, receptionMachinerySpecialEquipment, receptionMachineryExtraInfo } = useOneDataColumnConfigs()
const { machineryHeadingsByMachineCategory: showMachineryHeadingsByMachineCategory, machineryFieldsByMachineCategory: showMachineryFieldsByMachineCategory } = useFormOptionalConditions()

const category = computed(() => props.receptionMachinery.category as MachineryCategories)
const drive = computed(() => props.receptionMachinery.drive?.name as MachineryDrives || undefined)
</script>

<template>
  <div class="space-y-4">
    <div>
      <h2 class="Heading">
        {{ $t('receptionMachinery.vehicle.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryVehicle }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'platform', category)">
      <h2 class="Heading">
        {{ $t('receptionMachinery.platform.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryPlatform }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'mastdaten', category)">
      <h2 class="Heading">
        {{ $t('receptionMachinery.mast.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryMast }" />
    </div>
    <div>
      <h2 class="Heading">
        {{ $t('machinery.field.specialEquipment.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachinerySpecialEquipment }" />
    </div>
    <div>
      <h2 class="Heading">
        {{ $t('receptionMachinery.dimension.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryDimension }" />
    </div>
    <div>
      <h2 class="Heading">
        {{ $t('receptionMachinery.tires.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryTires }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'antrieb', category)">
      <h2 class="Heading">
        {{ $t('machinery.field.drive.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryMotor }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'batteriedaten', category)">
      <h2 class="Heading">
        {{ $t('receptionMachinery.battery.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryBattery }" />
    </div>
    <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'lenkung', category)">
      <h2 class="Heading">
        {{ $t('receptionMachinery.steering.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachinerySteeringType }" />
    </div>
    <div>
      <h2 class="Heading">
        {{ $t('receptionMachinery.extraInfo.name') }}
      </h2>
      <TableOneDataColumn :config="{ data: receptionMachinery, columns: receptionMachineryExtraInfo }" />
    </div>
    <div>
      <h2 class="Heading">
        {{ $t('common.photos') }}
      </h2>
      <FileView
        :label="$t('receptionMachinery.field.basicDataPhotos.name')"
        :files="receptionMachinery.basicDataPhotos"
        collapsed-by-default
      />
      <FileView
        :label="$t('receptionMachinery.field.generalPhotos.name')"
        :files="receptionMachinery.generalPhotos"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinInsidePhotos', category)"
        :label="$t('receptionMachinery.field.cabinInsidePhotos.name')"
        :files="receptionMachinery.cabinInsidePhotos"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinOutsidePhotos', category)"
        :label="$t('receptionMachinery.field.cabinOutsidePhotos.name')"
        :files="receptionMachinery.cabinOutsidePhotos"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'tiresPhotos', category)"
        :label="$t('receptionMachinery.tire.field.photos.name')"
        :files="receptionMachinery.tire?.photos ?? []"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'batteryPhotos', category, drive)"
        :label="$t('receptionMachinery.battery.field.photos.name')"
        :files="receptionMachinery.battery?.photos ?? []"
        collapsed-by-default
      />

      <FileView
        v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'motorPhotos', category, drive)"
        :label="$t('receptionMachinery.motor.field.photos.name')"
        :files="receptionMachinery.motor?.photos ?? []"
        collapsed-by-default
      />

      <FileView
        :label="$t('common.videos')"
        :files="receptionMachinery.videos"
        collapsed-by-default
      />
    </div>
  </div>
</template>

<style scoped>
.Heading {
  @apply text-lg font-semibold pb-1;
}
</style>
