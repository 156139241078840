<script lang="ts" setup>
const { t: $t } = useI18n()

import { createLocalStoragePlugin } from '@formkit/addons'
import type { LocalStorageOptions } from '@formkit/addons'
import type { ReceptionMachineryTireType } from '@prisma/client'
import { equipmentType, machineryGears, machineryHals, machineryMasts, machineryMastsForBuehnen, machinerySteeringType, machineryTireColors, receptionMachineryTireCountSchema } from '~/server/schemas'
import { machineryFemsWithFiveAndNone } from '~/server/schemas/machineryAndMachineryAccessoryCommon'
import type { ApiMachineryDriveGetAll, ApiMachineryTypeGetAll, ApiReceptionMachinerySpecialEquipment, MachineryCategories, UpdateOrCreateReceptionMachinery } from '~/types'
import { gearOptionsToGerman } from '~/translations'

const props = defineProps<{ payload: UpdateOrCreateReceptionMachinery, specialEquipments: ApiReceptionMachinerySpecialEquipment[], tireTypes: ReceptionMachineryTireType[], machineryTypes: ApiMachineryTypeGetAll[], machineryDrives: ApiMachineryDriveGetAll[], localStorageKey?: LocalStorageOptions, createdAccessoryId?: string, localStorageDisabled?: boolean, disabled?: boolean }>()
const emit = defineEmits<{ (e: 'save', payload: UpdateOrCreateReceptionMachinery): void }>()
const { cloned } = useCloned(props.payload)

const { drive, machineryTire, machineryBattery, machineryMotor, machinerySpecialEquipments } = toRefs(cloned.value.data)
const { category } = cloned.value.data.receptionMachinery

const { machineryHeadingsByMachineCategory: showMachineryHeadingsByMachineCategory, machineryFieldsByMachineCategory: showMachineryFieldsByMachineCategory, machineryPropulsionFieldsByMachineCategory: showMachineryPropulsionFieldsByMachineCategory, machineryFieldsNotRequiredByMachineCategory } = useFormOptionalConditions()

function useIsRequiredByFilters(filters: Record<string, MachineryCategories[]>, field: string, category: MachineryCategories) {
  return !filters[field].includes(category)
}

// Set default value for `hasAdBlue` if applicable
if (useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'hasAdBlue', category, drive.value)) {
  machineryMotor.value.hasAdBlue = machineryMotor.value.hasAdBlue ?? false
}

const selectedAmpere = ref<SelectOption>()
const actualAmpere = ref<number>()

// Ampere Options
const quickOptions = [80, 160, 320, 640] as const

type SelectOption = keyof typeof quickOptions | 'other'
type Options = { [I in SelectOption]: string }
const machineryAmpereOptions: Options = Object.fromEntries(quickOptions.map(v => [v, `${v}A`]).concat([['other', $t('common.other')]]))

function onAmpereChange(v: SelectOption) {
  if (v === 'other') {
    actualAmpere.value = 0
  } else {
    actualAmpere.value = Number.parseInt(v as string, 10) || 0
  }
}

onMounted(() => {
  const ampere = cloned.value.data.machineryBattery.ampere
  if (ampere) {
    actualAmpere.value = ampere
    if (!quickOptions.includes(ampere as typeof quickOptions[number])) {
      selectedAmpere.value = 'other'
    }
  }
})

const machinerySpecialEquipmentOptions = computed(() => props.specialEquipments.filter(equipment => equipment.supportCategories?.includes(category)).map(equipment => ({
  label: equipment.typeName,
  value: equipment.id,
  equipmentType: equipment.equipmentType,
})))

const specialEquipment = reactive<Record<string, string[]>>({})
watch(machinerySpecialEquipmentOptions, (options) => {
  if (Object.keys(specialEquipment).length > 0) {
    return
  }
  for (const equipment of options) {
    const filtered = machinerySpecialEquipments.value.filter(option => option === equipment.value)
    if (specialEquipment[equipment.equipmentType]) {
      specialEquipment[equipment.equipmentType].push(...filtered)
    } else {
      specialEquipment[equipment.equipmentType] = filtered
    }
  }
}, { immediate: true })

const machineryTireTypeOptions = computed(() => props.tireTypes.map(type => ({
  label: type.typeName,
  value: type.id,
})))

const machineryGearOptions = machineryGears.options.map((gear) => {
  return {
    label: gearOptionsToGerman[gear],
    value: gear,
  }
})

const machineryTypeOptions = computed(() => props.machineryTypes.filter(type => type.category === category).map(type => ({
  label: `${type.name}`,
  value: type.id,
})))

const machineryDrivesOptions = computed(() => props.machineryDrives.map(drive => ({
  label: $t(`machinery.drive.${drive.name}`),
  value: drive.id,
})))

function submit() {
  const specialEquipments: string[] = []
  Object.values(specialEquipment).forEach((values) => {
    if (!values) {
      return
    }
    values.forEach(equipment => specialEquipments.push(equipment))
  })
  machinerySpecialEquipments.value = specialEquipments

  cloned.value.data.machineryBattery.ampere = actualAmpere.value

  emit('save', cloned.value)
}

const initialLiftOptions = [
  {
    label: $t('common.yes'),
    value: true,
  },
  {
    label: $t('common.no'),
    value: false,
  },
]

const { openAccessoryCreateOrEditPopup } = useGlobalOpeners()

const formkitPlugins = useFormkitPlugins()

const allFormkitPlugins = computed(() => {
  const plugins = [formkitPlugins]
  if (!props.localStorageDisabled) {
    plugins.push(createLocalStoragePlugin(props.localStorageKey))
  }
  return plugins
})
</script>

<template>
  <FormKit
    v-slot="{ value }"
    v-model="cloned.data.receptionMachinery"
    name="MachineryCreationReceptionProtocolForm"
    type="form"
    :plugins="allFormkitPlugins"
    use-local-storage
    :config="{ validationVisibility: 'submit' }"
    :disabled="disabled"
    @submit="submit"
  >
    <TheDevOnlyNiceJson :value="value" :cloned="cloned" />
    <div class="w-full flex flex-col gap-y-2 mb-6">
      <FormKit
        v-if="cloned.data.receptionMachinery.machineryId"
        id="machineryId"
        :label="$t('common.field.id.name')"
        type="text"
        :disabled="true"
      />
      <h2 class="Heading">
        {{ $t('receptionMachinery.vehicle.name') }}
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <div class="WidthHalfMinusGap">
          <FormKit
            id="yearBuilt"
            :label="$t('machinery.field.yearBuilt.name')"
            type="number"
            :placeholder="$t('machinery.field.yearBuilt.placeholder')"
            validation="min:1900|max:2030"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="chassisNumber"
            :label="$t('receptionMachinery.field.chassisNumber.name')"
            type="text"
            :placeholder="$t('receptionMachinery.field.chassisNumber.placeholder')"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="serialNumber"
            :label="$t('receptionMachinery.field.serialNumber.name')"
            type="text"
            :placeholder="$t('receptionMachinery.field.serialNumber.placeholder')"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="product"
            :label="$t('receptionMachinery.field.product.name')"
            type="text"
            :placeholder="$t('receptionMachinery.field.product.placeholder')"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="driveId"
            :value="cloned.data.receptionMachinery.driveId ? cloned.data.receptionMachinery.driveId : undefined"
            type="select"
            :options="machineryDrivesOptions"
            :label="$t('machinery.field.drive.name')"
            :placeholder="$t('machinery.field.drive.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'liftingWeight', category)" class="WidthHalfMinusGap">
          <FormKit
            id="liftingWeight"
            :label="$t('machinery.field.liftingWeight.name')"
            type="number"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'basketLoad', category)" class="WidthHalfMinusGap">
          <FormKit
            id="basketLoad"
            :label="$t('receptionMachinery.field.basketLoad.name')"
            type="number"
            step="0.01"
            validation="min:0"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'tractionForce', category)" class="WidthHalfMinusGap">
          <FormKit
            id="tractionForce"
            :label="$t('receptionMachinery.tractionForce.label')"
            type="number"
            step="0.01"
            validation="min:0"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'loadCenter', category)" class="WidthHalfMinusGap">
          <FormKit
            id="loadCenter"
            :label="$t('receptionMachinery.field.loadCenter.name')"
            type="text"
            :placeholder="$t('receptionMachinery.field.loadCenter.placeholder')"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="opticalCondition"
            :label="$t('receptionMachinery.field.opticalCondition.name')"
            type="text"
            :placeholder="$t('receptionMachinery.field.opticalCondition.placeholder')"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="technicalCondition"
            :label="$t('receptionMachinery.field.technicalCondition.name')"
            type="text"
            :placeholder="$t('receptionMachinery.field.opticalCondition.placeholder')"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="weight"
            :label="$t('receptionMachinery.field.weight.name')"
            type="number"
            step="0.01"
            :placeholder="$t('receptionMachinery.field.weight.placeholder')"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKitMultiSelect
            id="typeId"
            :label="$t('common.field.type.name')"
            :multi-select-options="machineryTypeOptions"
            :is-multi-select-allowed="false"
            :placeholder="$t('machinery.field.type.placeholder')"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="typeAddition"
            :label="$t('receptionMachinery.field.typeAddition.name')"
            type="text"
            :placeholder="$t('machinery.field.type.placeholder')"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="initialOperatingHours"
            :label="$t('receptionMachinery.field.operatingHours.name')"
            type="number"
            step="0.1"
            :placeholder="$t('receptionMachinery.field.operatingHours.placeholder')"
            validation-visibility="live"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="key"
            :label="$t('receptionMachinery.field.key.name')"
            type="text"
            :placeholder="$t('receptionMachinery.field.key.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'initialLift', category)" class="WidthHalfMinusGap">
          <FormKit
            id="initialLift"
            :value="cloned.data.receptionMachinery.initialLift ?? undefined"
            type="select"
            :options="initialLiftOptions"
            :label="$t('receptionMachinery.field.initialLift.name')"
            :placeholder="$t('common.no')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'seats', category)" class="WidthHalfMinusGap">
          <FormKit
            id="seats"
            type="number"
            number="integer"
            validation="min:0"
            :label="$t('receptionMachinery.field.seats.name')"
            :placeholder="$t('common.field.count.placeholder')"
          />
        </div>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'platform', category)" class="w-full flex flex-col gap-y-2">
        <h2 class="Heading">
          {{ $t('receptionMachinery.platform.name') }}
        </h2>
        <div class="flex gap-x-2 flex-wrap">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="platformLengthInMillimeters"
              :label="$t('receptionMachinery.field.platformLength.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>
          <div class="WidthHalfMinusGap">
            <FormKit
              id="platformWidthInMillimeters"
              :label="$t('receptionMachinery.field.platformWidth.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>

          <div class="WidthHalfMinusGap">
            <FormKit
              id="platformHeightInMillimeters"
              :label="$t('receptionMachinery.field.platformHeight.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>
        </div>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'mastdaten', category)">
        <h2 class="Heading">
          {{ $t('receptionMachinery.mast.name') }}
        </h2>
        <div class="flex gap-x-2 flex-wrap">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="mast"
              :label="$t('receptionMachinery.field.mast.name')"
              type="select"
              :options="useTranslatedSelectOptions('machinery.mast', category === 'platform' ? machineryMastsForBuehnen.options : machineryMasts.options)"
              :placeholder="$t(`machinery.mast.${machineryMasts.options[0]}`)"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'workHeightInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="workHeightInMillimeters"
              :label="$t('receptionMachinery.field.workHeight.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>

          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'liftingHeightInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="liftingHeightInMillimeters"
              :label="$t('receptionMachinery.field.liftingHeight.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'sideReachInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="sideReachInMillimeters"
              :label="$t('receptionMachinery.field.sideReach.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'mastOverallHeightInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="mastOverallHeightInMillimeters"
              :label="$t('receptionMachinery.field.mastOverallHeight.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'freeLiftInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="freeLiftInMillimeters"
              :label="$t('receptionMachinery.field.freeLift.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>

          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'forkCarriageWidthInMillimeters', category)" class="WidthHalfMinusGap">
            <FormKit
              id="forkCarriageWidthInMillimeters"
              :label="$t('receptionMachinery.field.freeLift.name')"
              type="number"
              step="0.01"
              :placeholder="$t('common.field.dimension.placeholder')"
            />
          </div>

          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'fem', category)" class="WidthHalfMinusGap">
            <FormKit
              id="fem"
              :label="$t('receptionMachinery.field.fem.name')"
              type="select"
              :options="machineryFemsWithFiveAndNone.options"
              :placeholder="machineryFemsWithFiveAndNone.options[0]"
              validation="required"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'hals', category)" class="WidthHalfMinusGap">
            <FormKit
              id="hals"
              :label="$t('receptionMachinery.field.hals.name')"
              type="select"
              :options="machineryHals.options"
              :placeholder="machineryHals.options[0]"
            />
          </div>
          <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'addOns', category)" class="WidthHalfMinusGap">
            <FormKit
              id="addOns"
              :label="$t('receptionMachinery.field.addOns.name')"
              type="text"
              :placeholder="$t('receptionMachinery.field.addOns.placeholder')"
            />
          </div>
          <div v-if="category === 'forklift' && payload.mode === 'create'" class="WidthHalfMinusGap flex items-center">
            <n-button
              v-if="!createdAccessoryId"
              style="width: 100%"
              @click="openAccessoryCreateOrEditPopup.open({ mode: 'create', category: 'fork', isCreatedIdRequired: true })"
            >
              Verbundene Gabeln anlegen
            </n-button>
            <n-button
              v-else
              style="width: 100%"
              @click="openAccessoryCreateOrEditPopup.open({ mode: 'update', id: createdAccessoryId, isUpdatingFromReception: true })"
            >
              Verbundene Gabeln {{ createdAccessoryId }} bearbeiten
            </n-button>
          </div>
        </div>
      </div>
      <h2 class="Heading">
        {{ $t('machinery.field.specialEquipment.name') }}
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <div v-for="type of equipmentType.options" :key="type" class="WidthHalfMinusGap">
          <FormKitMultiSelect
            v-model="specialEquipment[type]"
            :label="type"
            :multi-select-options="machinerySpecialEquipmentOptions.filter(equipment => equipment.equipmentType === type)"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="customSpecialEquipment"
            :label="$t('receptionMachinery.field.customSpecialEquipment.name')"
            type="text"
            :placeholder="$t('machinery.field.specialEquipment.name')"
          />
        </div>
      </div>

      <h2 class="Heading">
        {{ $t('receptionMachinery.dimension.name') }}
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'lengthToForkfaceInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="lengthToForkfaceInMillimeters"
            :label="$t('receptionMachinery.field.lengthToForkface.name')"
            type="number"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'totalLengthInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="totalLengthInMillimeters"
            :label="$t('receptionMachinery.field.totalLength.name')"
            type="number"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="wheelbaseInMillimeters"
            :label="$t('receptionMachinery.field.wheelbase.name')"
            type="number"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>

        <div class="WidthHalfMinusGap">
          <FormKit
            id="overallWidthInMillimeters"
            :label="$t('receptionMachinery.field.overallWidth.name')"
            type="number"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div class="WidthHalfMinusGap">
          <FormKit
            id="groundClearanceCenter"
            :label="$t('receptionMachinery.field.overallWidth.name')"
            type="text"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>

        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinHeightInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="cabinHeightInMillimeters"
            :label="$t('receptionMachinery.field.cabinHeight.name')"
            type="number"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'dimensionHeightInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="dimensionHeightInMillimeters"
            :label="$t('common.field.height.name')"
            type="number"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'overallHeightInMillimeters', category)" class="WidthHalfMinusGap">
          <FormKit
            id="overallHeightInMillimeters"
            :label="$t('receptionMachinery.field.overallHeight.name')"
            type="number"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'outreach', category)" class="WidthHalfMinusGap">
          <FormKit
            id="outreach"
            :label="$t('receptionMachinery.field.outreach.name')"
            type="number"
            validation="min:0"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
        <div v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'hookHeight', category)" class="WidthHalfMinusGap">
          <FormKit
            id="hookHeight"
            :label="$t('receptionMachinery.field.outreach.name')"
            type="number"
            validation="min:0"
            step="0.01"
            :placeholder="$t('common.field.dimension.placeholder')"
          />
        </div>
      </div>

      <h2 class="Heading">
        {{ $t('receptionMachinery.tires.name') }}
      </h2>
      <div class="flex gap-x-2 flex-wrap">
        <FormKit v-model="machineryTire" type="group">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="producerCompanyName"
              :label="$t('machinery.field.producerCompanyName.name')"
              type="text"
              :placeholder="$t('receptionMachinery.field.producerCompanyName.placeholder')"
            />
          </div>
          <div class="WidthHalfMinusGap">
            <FormKit
              id="typeName"
              :label="$t('common.field.type.name')"
              type="text"
              :placeholder="$t('receptionMachinery.field.type.placeholder')"
            />
          </div>

          <div class="WidthHalfMinusGap">
            <FormKit
              id="color"
              :label="$t('receptionMachinery.tire.field.color.name')"
              type="select"
              :options="machineryTireColors.options"
              :placeholder="machineryTireColors.options[0]"
            />
          </div>
          <div class="WidthHalfMinusGap">
            <FormKitMultiSelect
              id="tireTypes"
              v-model="cloned.data.machineryTireTypes"
              :label="$t('receptionMachinery.tire.field.tireType.name')"
              :multi-select-options="machineryTireTypeOptions"
            />
          </div>
          <FormKit
            v-if="cloned.data.receptionMachinery.category === 'forklift'"
            id="tireCount"
            :label="$t('receptionMachinery.tire.field.tireCount.name')"
            type="select"
            :options="receptionMachineryTireCountSchema.options"
            :placeholder="receptionMachineryTireCountSchema.options[0]"
          />

          <h3 class="SubHeading">
            {{ $t('receptionMachinery.tire.field.front.name') }}
          </h3>
          <div class="flex gap-x-2 flex-wrap">
            <div class="WidthHalfMinusGap">
              <FormKit
                id="frontSize"
                :label="$t('common.field.size.name')"
                type="text"
                :placeholder="$t('common.field.size.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="frontCount"
                :label="$t('common.field.count.name')"
                type="number"
                :placeholder="$t('common.field.count.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="frontCondition"
                :label="$t('common.field.condition.name')"
                type="text"
                :placeholder="$t('common.field.condition.placeholder')"
              />
            </div>
          </div>

          <h3 class="SubHeading">
            {{ $t('receptionMachinery.tire.field.rear.name') }}
          </h3>
          <div class="flex gap-x-2 flex-wrap">
            <div class="WidthHalfMinusGap">
              <FormKit
                id="rearSize"
                :label="$t('common.field.size.name')"
                type="text"
                :placeholder="$t('common.field.size.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="rearCount"
                :label="$t('common.field.count.name')"
                type="text"
                :placeholder="$t('common.field.count.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="rearCondition"
                :label="$t('common.field.condition.name')"
                type="text"
                :placeholder="$t('common.field.condition.placeholder')"
              />
            </div>
          </div>
        </FormKit>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'antrieb', category)">
        <h2 class="Heading">
          {{ $t('machinery.field.drive.name') }}
        </h2>
        <FormKit v-model="machineryMotor" type="group">
          <div class="flex gap-x-2 flex-wrap">
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'producerCompanyName', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="producerCompanyName"
                :label="$t('receptionMachinery.motor.field.producerCompanyName.name')"
                type="text"
                :placeholder="$t('receptionMachinery.motor.field.producerCompanyName.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="power"
                :label="$t('receptionMachinery.motor.field.power.name')"
                type="number"
                step="0.01"
                :placeholder="$t('receptionMachinery.motor.field.power.placeholder')"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'typeName', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="typeName"
                :label="$t('common.field.type.name')"
                type="text"
                :placeholder="$t('receptionMachinery.motor.field.type.placeholder')"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'gear', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="gear"
                :label="$t('receptionMachinery.motor.field.gear.name')"
                type="select"
                :options="machineryGearOptions"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'serialNumber', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="serialNumber"
                :label="$t('receptionMachinery.motor.field.serialNumber.name')"
                type="text"
                :placeholder="$t('receptionMachinery.motor.field.serialNumber.placeholder')"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'gearProducer', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="gearProducer"
                :label="$t('receptionMachinery.motor.field.gearProducer.name')"
                type="text"
                :placeholder="$t('receptionMachinery.motor.field.gearProducer.placeholder')"
              />
            </div>
            <div v-if="useShowFieldByFilters(showMachineryPropulsionFieldsByMachineCategory, 'hasAdBlue', category, drive)" class="WidthHalfMinusGap">
              <FormKit
                id="hasAdBlue"
                :label="$t('receptionMachinery.motor.field.hasAdBlue.name')"
                type="checkbox"
                outer-class="flex h-full items-center"
              />
            </div>
          </div>
        </FormKit>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'batteriedaten', category)">
        <h2 class="Heading">
          {{ $t('receptionMachinery.battery.name') }}
        </h2>
        <FormKit v-model="machineryBattery" type="group">
          <div class="flex gap-x-2 flex-wrap">
            <div class="WidthHalfMinusGap">
              <FormKit
                id="producerCompanyName"
                :label="$t('machinery.field.producerCompanyName.name')"
                type="text"
                :placeholder="$t('receptionMachinery.battery.field.producerCompanyName.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="typeName"
                :label="$t('common.field.type.name')"
                type="text"
                :placeholder="$t('receptionMachinery.battery.field.type.placeholder')"
              />
            </div>

            <div class="WidthHalfMinusGap">
              <FormKit
                id="weight"
                :label="$t('common.field.weight.name')"
                type="number"
                step="0.01"
                :placeholder="$t('common.field.weight.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="volt"
                :label="$t('receptionMachinery.field.volt.name')"
                type="text"
                :placeholder="$t('receptionMachinery.field.volt.placeholder')"
              />
            </div>

            <div class="WidthHalfMinusGap">
              <FormKit
                id="yearBuilt"
                :label="$t('machinery.field.yearBuilt.name')"
                type="number"
                :placeholder="$t('machinery.field.yearBuilt.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="serialNumber"
                :label="$t('machinery.field.serialNumber.name')"
                type="text"
                :placeholder="$t('machinery.field.serialNumber.placeholder')"
              />
            </div>
            <div class="WidthHalfMinusGap">
              <FormKit
                id="ampere"
                v-model="selectedAmpere"
                :label="$t('receptionMachinery.battery.field.ampere.name')"
                type="select"
                :placeholder="$t('receptionMachinery.battery.field.ampere.placeholder')"
                :options="machineryAmpereOptions"
                @input="(v: SelectOption) => onAmpereChange(v)"
              />
            </div>
            <div v-if="selectedAmpere === 'other'" class="WidthHalfMinusGap">
              <FormKit
                v-model="actualAmpere"
                min="0"
                type="number"
                number="integer"
                :label="$t('receptionMachinery.battery.field.otherAmpere.name')"
                :placeholder="$t('receptionMachinery.battery.field.otherAmpere.placeholder')"
              />
            </div>
          </div>
        </FormKit>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'lenkung', category)" class="w-full flex flex-col gap-y-2">
        <h2 class="Heading">
          {{ $t('receptionMachinery.steering.name') }}
        </h2>
        <div class="flex gap-x-2 flex-wrap">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="steeringType"
              :label="$t('receptionMachinery.field.steeringType.name')"
              type="select"
              :options="machinerySteeringType.options"
              :placeholder="machinerySteeringType.options[0]"
            />
          </div>
        </div>
      </div>

      <div v-if="useShowFieldByFilters(showMachineryHeadingsByMachineCategory, 'weitereInfos', category)" class="w-full flex flex-col gap-y-2">
        <h2 class="Heading">
          Weitere Infos
        </h2>
        <div class="flex gap-x-2 flex-wrap">
          <div class="WidthHalfMinusGap">
            <FormKit
              id="extraAddOns"
              :label="$t('receptionMachinery.field.addOns.name')"
              type="text"
              :placeholder="$t('receptionMachinery.field.addOns.placeholder')"
            />
          </div>
          <div class="WidthHalfMinusGap">
            <FormKit
              id="extraAccessories"
              :label="$t('receptionMachinery.field.extraAccessories.name')"
              type="text"
              :placeholder="$t('receptionMachinery.field.extraAccessories.placeholder')"
            />
          </div>
        </div>
      </div>

      <h2 class="Heading">
        Fotos
      </h2>
      <div class="w-full space-y-2">
        <FormKitFileDropzone
          id="basicDataPhotos"
          :label="$t('receptionMachinery.field.basicDataPhotos.name')"
          :read-only="disabled"
          :disabled="false"
          wrap-path-in-object
        />
        <FormKitFileDropzone
          id="generalPhotos"
          :label="`${$t('receptionMachinery.field.basicDataPhotos.name')} (Mindestens 2)`"
          :read-only="disabled"
          :disabled="false"
          wrap-path-in-object
        />
        <FormKitFileDropzone
          v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinInsidePhotos', category)"
          id="cabinInsidePhotos"
          :label="`${$t('receptionMachinery.field.cabinInsidePhotos.name')} ${useIsRequiredByFilters(machineryFieldsNotRequiredByMachineCategory, 'cabinInsidePhotos', category) ? '(Mindestens 2)' : '(Empfohlen 2)'} `"
          :read-only="disabled"
          :disabled="false"
          wrap-path-in-object
        />
        <FormKitFileDropzone
          v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'cabinOutsidePhotos', category)"
          id="cabinOutsidePhotos"
          :label="`${$t('receptionMachinery.field.cabinOutsidePhotos.name')} ${useIsRequiredByFilters(machineryFieldsNotRequiredByMachineCategory, 'cabinOutsidePhotos', category) ? '(Mindestens 2)' : '(Empfohlen 2)'} `"
          :read-only="disabled"
          :disabled="false"
          wrap-path-in-object
        />
        <FormKit v-model="machineryTire" type="group">
          <FormKitFileDropzone
            v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'tiresPhotos', category)"
            id="photos"
            :label="`${$t('receptionMachinery.tire.field.photos.name')} (Mindestens 2)`"
            :read-only="disabled"
            :disabled="false"
            wrap-path-in-object
          />
        </FormKit>
        <FormKit v-model="machineryBattery" type="group">
          <FormKitFileDropzone
            v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'batteryPhotos', category, drive)"
            id="photos"
            :label="`${$t('receptionMachinery.battery.field.photos.name')} (Mindestens 1)`"
            :read-only="disabled"
            :disabled="false"
            wrap-path-in-object
          />
        </FormKit>
        <FormKit v-model="machineryMotor" type="group">
          <FormKitFileDropzone
            v-if="useShowFieldByFilters(showMachineryFieldsByMachineCategory, 'motorPhotos', category, drive)"
            id="photos"
            :label="`${$t('receptionMachinery.motor.field.photos.name')} (Mindestens 1)`"
            :read-only="disabled"
            :disabled="false"
            wrap-path-in-object
          />
        </FormKit>
        <FormKitFileDropzone
          id="videos"
          :label="$t('common.videos')"
          :read-only="disabled"
          :disabled="false"
          wrap-path-in-object
        />
      </div>

      <n-collapse v-if="cloned.data.receptionMachinery.machineryId">
        <n-collapse-item>
          <template #header>
            <span class="text-lg font-bold">
              {{ $t('common.field.condition.name') }}
            </span>
          </template>
          <div class="flex w-full gap-x-4 gap-y-2">
            <DefectPage :create-mode-options="{ itemId: cloned.data.receptionMachinery.machineryId, type: 'machinery' }" />
          </div>
        </n-collapse-item>
      </n-collapse>
    </div>
  </FormKit>
</template>

<style scoped>
.Heading {
  @apply text-xl font-bold py-4
}
.SubHeading {
  @apply text-lg font-bold py-4
}
.WidthHalfMinusGap {
  width: calc(50% - 0.5rem)
}

.CheckboxStyle {
  @apply flex pt-4 items-center
}
.RemoveCheckboxBackground :deep(.formkit-inner) {
  background: none;
}
</style>
