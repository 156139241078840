// This file was generated by a custom prisma generator, do not edit manually.
/* eslint-disable ts/no-redeclare */
export const StoragePositionStatus = {
  Created: 'Created',
  RequestedForStorage: 'RequestedForStorage',
  Offloaded: 'Offloaded',
  Stored: 'Stored',
  NotDelivered: 'NotDelivered',
  StorageApproved: 'StorageApproved',
  NotDeliveredApproved: 'NotDeliveredApproved',
  RequestedForRetrieving: 'RequestedForRetrieving',
  FullyOrdered: 'FullyOrdered',
  Retrieved: 'Retrieved',
} as const

export type StoragePositionStatus = (typeof StoragePositionStatus)[keyof typeof StoragePositionStatus]

export const ExternalSalesMarketplaceId = {
  MachineryZone: 'MachineryZone',
  ForkliftInternational: 'ForkliftInternational',
  Supralift: 'Supralift',
  Maschinensucher: 'Maschinensucher',
  Mascus: 'Mascus',
} as const

export type ExternalSalesMarketplaceId = (typeof ExternalSalesMarketplaceId)[keyof typeof ExternalSalesMarketplaceId]

export const ExternalSalesMarketplaceExportResult = {
  Success: 'Success',
  PartialSuccess: 'PartialSuccess',
  Failure: 'Failure',
} as const

export type ExternalSalesMarketplaceExportResult = (typeof ExternalSalesMarketplaceExportResult)[keyof typeof ExternalSalesMarketplaceExportResult]

export const RentalPriceModifierUnit = {
  Absolute: 'Absolute',
  Percent: 'Percent',
} as const

export type RentalPriceModifierUnit = (typeof RentalPriceModifierUnit)[keyof typeof RentalPriceModifierUnit]

export const RentalPriceModifierConditionType = {
  Days: 'Days',
  CustomerType: 'CustomerType',
} as const

export type RentalPriceModifierConditionType = (typeof RentalPriceModifierConditionType)[keyof typeof RentalPriceModifierConditionType]

export const RentalPriceModifierCustomerType = {
  Dealer: 'Dealer',
  DealerAndPartnerLift: 'DealerAndPartnerLift',
  DealerAndSystemLift: 'DealerAndSystemLift',
  EndCustomer: 'EndCustomer',
} as const

export type RentalPriceModifierCustomerType = (typeof RentalPriceModifierCustomerType)[keyof typeof RentalPriceModifierCustomerType]
