import type { ApiMachineryGetById, ApiPublicMachineryGetById, ApiReceptionMachineryGetById } from '~/types'

export default (serverI18n?: I18n) => {
  const i18n = serverI18n ?? useNuxtApp().$i18n

  const machineryBaseInformation: OneDataColumn<ApiMachineryGetById>[] = [
    {
      title: i18n.t('common.field.id.name'),
      key: 'id',
    },
    {
      title: i18n.t('common.field.status.name'),
      key: 'status',
      render: ({ status }) => i18n.t(`machinery.status.${status}`),
    },
  ]

  const machineryType: OneDataColumn<ApiPublicMachineryGetById['type']>[] = [
    {
      title: i18n.t('common.field.type.name'),
      key: 'name',
    },
  ]

  const machineryPublicVehicleData: OneDataColumn<ApiPublicMachineryGetById>[] = [
    {
      title: i18n.t('machinery.field.producerCompanyName.name'),
      key: 'producerCompanyName',
      hideIfNullish: true,
    },
    {
      title: i18n.t('machinery.field.rubric.name'),
      key: 'machineryRubric',
      render: ({ machineryRubric }) => i18n.t(`machinery.rubric.${machineryRubric}`),
      hideIfNullish: true,
    },
    {
      title: i18n.t('common.field.type.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.type?.name,
      hideIfNullish: true,
    },
    {
      title: i18n.t('machinery.field.yearBuilt.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.yearBuilt,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.product.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.product,
      hideIfNullish: true,
    },
    {
      title: i18n.t('machinery.field.drive.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.drive ? i18n.t(`machinery.drive.${receptionMachinery.drive.name}`) : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('common.field.weight.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.weight,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.operatingHours.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.operatingHours ? (receptionMachinery.operatingHours + 50).toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
  ]

  const machineryPublicDimensions: OneDataColumn<ApiPublicMachineryGetById>[] = [
    {
      title: i18n.t('receptionMachinery.field.mast.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.mast ? i18n.t(`machinery.mast.${receptionMachinery.mast}`) : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('machinery.field.liftingHeight.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.liftingHeightInMillimeters ? receptionMachinery.liftingHeightInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.overallHeight.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => {
        const heightInMillimeters = receptionMachinery?.mastOverallHeightInMillimeters ?? receptionMachinery?.overallHeightInMillimeters
        return heightInMillimeters ? heightInMillimeters.toLocaleString('de-DE') : undefined
      },
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.forkCarriageWidth.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.forkCarriageWidthInMillimeters ? receptionMachinery.forkCarriageWidthInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.fem.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.fem,
      hideIfNullish: true,
    },
  ]

  const machineryPublicMeasurements: OneDataColumn<ApiPublicMachineryGetById>[] = [
    {
      title: i18n.t('receptionMachinery.field.lengthToForkface.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.lengthToForkfaceInMillimeters ? receptionMachinery.lengthToForkfaceInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.totalLength.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.totalLengthInMillimeters ? receptionMachinery.totalLengthInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.wheelbase.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.wheelbaseInMillimeters ? receptionMachinery.wheelbaseInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.overallWidth.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.overallWidthInMillimeters ? receptionMachinery.overallWidthInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.groundClearanceCenter.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.groundClearanceCenter,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.cabinHeight.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.cabinHeightInMillimeters ? receptionMachinery.cabinHeightInMillimeters.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
  ]

  const machineryPublicBatteryData: OneDataColumn<ApiPublicMachineryGetById>[] = [
    {
      title: i18n.t('receptionMachinery.field.producerCompanyName.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.battery?.producerCompanyName,
      hideIfNullish: true,
    },
    {
      title: i18n.t('common.field.type.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.battery?.typeName,
      hideIfNullish: true,
    },
    {
      title: i18n.t('common.field.weight.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.battery?.weight ? receptionMachinery.battery.weight.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.field.volt.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.battery?.volt,
      hideIfNullish: true,
    },
    {
      title: i18n.t('machinery.field.yearBuilt.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.battery?.yearBuilt,
      hideIfNullish: true,
    },
  ]

  const machineryPublicMotorData: OneDataColumn<ApiPublicMachineryGetById>[] = [
    {
      title: i18n.t('receptionMachinery.motor.field.producerCompanyName.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.motor?.producerCompanyName,
      hideIfNullish: true,
    },
    {
      title: i18n.t('receptionMachinery.motor.field.power.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.motor?.power ? receptionMachinery.motor.power.toLocaleString('de-DE') : undefined,
      hideIfNullish: true,
    },
    {
      title: i18n.t('common.field.type.name'),
      key: 'receptionMachinery',
      render: ({ receptionMachinery }) => receptionMachinery?.motor?.typeName,
      hideIfNullish: true,
    },
  ]

  const receptionMachinerySpecialEquipment: OneDataColumn<ApiReceptionMachineryGetById | Exclude<ApiPublicMachineryGetById['receptionMachinery'], null>>[] = [
    {
      title: i18n.t('machinery.field.specialEquipment.name'),
      key: 'receptionMachinerySpecialEquipmentRelation',
      render: ({ receptionMachinerySpecialEquipmentRelation, customSpecialEquipment }) => {
        const specialEquipments = receptionMachinerySpecialEquipmentRelation?.receptionMachinerySpecialEquipments?.map(type => type.typeName).join(', ')
        if (!specialEquipments && !customSpecialEquipment) {
          return undefined
        }
        return `${specialEquipments}${customSpecialEquipment ? `${specialEquipments ? ', ' : ''}${customSpecialEquipment}` : ''}`
      },
      hideIfNullish: true,
    },
  ]

  const machineryEquipment: OneDataColumn<ApiPublicMachineryGetById>[] = [
    {
      title: i18n.t('machineryAccessory.name', { count: 2 }),
      key: 'marketingMachineryAccessoryDescription',
    },
    {
      title: i18n.t('machinery.field.specialEquipment.name'),
      key: 'marketingSpecialEquipmentDescription',
    },
  ]

  return {
    machineryBaseInformation,
    machineryType,
    machineryPublicVehicleData,
    machineryPublicDimensions,
    machineryPublicMeasurements,
    machineryPublicBatteryData,
    machineryPublicMotorData,
    receptionMachinerySpecialEquipment,
    machineryEquipment,
  }
}
