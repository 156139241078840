import { keepPreviousData, useQuery } from '@tanstack/vue-query'
import { endOfDay, endOfToday, isBefore, startOfDay, startOfToday } from 'date-fns'
import type { z } from 'zod'
import { defu } from 'defu'
import type { Prisma } from '@prisma/client'
import type { ApiInputInvoiceGetAllOffersForPreparation, ApiInputOfferGetById, ApiTaskGetAllInput, FreelancerSalesGetMachineryReleasedForFreelancerConditionsInput, GroupedPhotoGroup, InvoiceType, LogisticsView, LogisticsVisualDetailsPollution, MachineryAccessoryCategory, MachineryAccessoryStatus, MachineryStatus, OfferCreate, OfferPositionCreateOrUpdate, OfferStatus, OfferType, Pagination, PartnerSalesGetMachineryReleasedForPartnerConditionsInput } from '~/types'
import type { logisticsTasksSortSchema, machineryByIdIncludeSchema } from '~/server/schemas'
import type { UserRole } from '~/authorization'

type _Where = Record<string, unknown>
type Where = Ref<_Where>
type MaybeRefWhere = MaybeRef<_Where>

export type CalendarMachineryAccessoryGroupedByCategoryArgs = Ref<{
  offerType: OfferType
  obligationStartsAt: Date | number
  obligationEndsAt?: Date | number
  machineryTypeId?: string
  where?: Where['value']
  selectedMachineryAccessoryCategories?: string[]
} | undefined>

export type OfferDataForOverlapCheck = Pick<OfferCreate, 'type' | 'obligationStartsAt' | 'obligationEndsAt' | 'obligationActuallyEndedAt'> & {
  id: string | false
  positions: string[]
}

interface DatesForOverlapCheck {
  start: Date
  end: Date | null
}

export default () => {
  const { $trpc } = useNuxtApp()

  return {
    machinery: {
      counts: (enabled: boolean) => useQuery({
        queryFn: () => $trpc.machinery.getCounts.query(),
        queryKey: ['machines', 'counts'],
        enabled,
      }),
      all: (where: Where, filterEligibleForTargetState?: Ref<MachineryStatus>, sorters?: MaybeRef<Record<string, 'asc' | 'desc'>[]>) => useQuery({
        queryFn: () => $trpc.machinery.getAll.query({ where: where.value, filterEligibleForTargetState: filterEligibleForTargetState?.value, sorters: unref(sorters) }),
        queryKey: ['machines', filterEligibleForTargetState, where, sorters],
        meta: { errorKey: 'Geräte' },
      }),
      allForOverview: (where: Where, filterEligibleForTargetState?: Ref<MachineryStatus>, pagination?: Ref<Pagination>, sorters?: ComputedRef<Record<string, 'asc' | 'desc'>[]>, hideMachineryWithActiveOrders?: Ref<boolean>, enabled?: boolean) => useQuery({
        queryFn: () => {
          const clientTodayInterval = hideMachineryWithActiveOrders?.value ? { start: startOfToday(), end: endOfToday() } : undefined

          return $trpc.machinery.getAllForOverview.query({ where: where.value, filterEligibleForTargetState: filterEligibleForTargetState?.value, pagination: pagination?.value, sorters: sorters?.value, clientTodayInterval })
        },
        queryKey: ['machines', filterEligibleForTargetState, where, pagination, sorters, hideMachineryWithActiveOrders],
        meta: { errorKey: 'Geräte' },
        placeholderData: keepPreviousData,
        enabled,
      }),
      getAllIds: (where: Where, disabled: Ref<boolean>, hideMachineryWithActiveOrders: Ref<boolean>) => useQuery({
        queryFn: () => {
          if (disabled.value) {
            return null
          }

          const clientTodayInterval = hideMachineryWithActiveOrders.value ? { start: startOfToday(), end: endOfToday() } : undefined

          return $trpc.machinery.getAllIds.query({ where: where.value, clientTodayInterval })
        },
        queryKey: ['machines', where, disabled, hideMachineryWithActiveOrders],
        meta: { errorKey: 'Geräte' },
        placeholderData: keepPreviousData,
      }),
      byId: (id: Ref<undefined | string>, include?: z.infer<typeof machineryByIdIncludeSchema>['include'], doKeepPreviousData = false, isForeignMachinery = false, enabled = true) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.machinery.getById.query({ id: id.value, isForeignMachinery, include })
        },
        queryKey: ['machines', id, include, doKeepPreviousData, isForeignMachinery],
        meta: { errorKey: 'Gerät' },
        placeholderData: doKeepPreviousData ? keepPreviousData : undefined,
        enabled,
      }),
      defectCountsByIds: (ids: Ref<undefined | string[]>) => useQuery({
        queryFn: () => {
          if (!ids.value) {
            return null
          }
          return $trpc.machinery.getDefectCountsByIds.query(ids.value)
        },
        queryKey: ['machines', ids],
        meta: { errorKey: 'Geräte' },
      }),
      soldMachineryByCustomerId: (id: Ref<undefined | string>, where: Where) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return []
          }

          return $trpc.machinery.getSoldMachineryByCustomerId.query({ id: id.value, where: where.value })
        },
        queryKey: ['machines', 'soldMachineryByCustomerId', id, where],
        meta: { errorKey: 'Geräte' },
      }),
      public: {
        byId: (id: Ref<string>) => useQuery({
          queryFn: () => $trpc.machinery.public.getById.query({ id: id.value }),
          queryKey: ['machines', id, 'public'],
          meta: { errorKey: 'Geräte' },
        }),
      },
      isReleasedForCurrentUser: (id: Ref<undefined | string>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.machinery.getIsReleasedForCurrentUser.query({ id: id.value })
        },
        queryKey: ['machines', id],
        meta: { errorKey: 'Geräte' },
      }),
    },
    calendar: {
      machinery: (where: Where, offerWhere: Where, firstCalendarDay: Ref<number>, lastCalendarDay: Ref<Date>, isIncludingOffers: Ref<boolean>, visibleTypes: Ref<OfferType[]>, numericalFilters?: ComputedRef<Record<string, number | undefined>>, showMachineryLocationRow?: Ref<boolean>, shouldOnlyIncludeItemsWithOffers?: Ref<boolean>, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.calendar.getMachinery.query({ where: where.value, offerWhere: offerWhere.value, firstCalendarDay: new Date(firstCalendarDay.value), lastCalendarDay: lastCalendarDay.value, isIncludingOffers: isIncludingOffers.value, visibleTypes: visibleTypes.value, numericalFilters: numericalFilters?.value, showMachineryLocationRow: showMachineryLocationRow?.value, shouldOnlyIncludeItemsWithOffers: shouldOnlyIncludeItemsWithOffers?.value, pagination: pagination?.value, startOfClientToday: startOfToday(), endOfClientOfToday: endOfToday() }),
        queryKey: ['machines', 'offer', 'receptionMachines', where, offerWhere, { status: 'approval' }, firstCalendarDay, lastCalendarDay, isIncludingOffers, visibleTypes, numericalFilters, showMachineryLocationRow, shouldOnlyIncludeItemsWithOffers, pagination],
        meta: { errorKey: 'Kalender-Einträge für Geräte' },
        refetchOnWindowFocus: false,
        refetchInterval: 2 * 60 * 1000,
        placeholderData: keepPreviousData,
      }),
      machineryAccessory: (where: Where, offerWhere: Where, firstCalendarDay: Ref<number>, lastCalendarDay: Ref<Date>, isIncludingOffers: Ref<boolean>, visibleTypes: Ref<OfferType[]>, selectedMachineryAccessoryCategories: Ref<string[]>, accessoryFem: Ref<string[] | null>, accessoryDrive: Ref<string | null>, accessoryVolt: Ref<string | null>, accessoryWheelSize: Ref<string | null>, accessoryComment: Ref<string | null>, accessoryProductCode: Ref<string | null>, accessoryNumericalFilters?: ComputedRef<Record<string, number | undefined>>, shouldOnlyIncludeItemsWithOffers?: Ref<boolean>, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.calendar.getMachineryAccessory.query({ where: where.value, offerWhere: offerWhere.value, firstCalendarDay: new Date(firstCalendarDay.value), lastCalendarDay: lastCalendarDay.value, isIncludingOffers: isIncludingOffers.value, visibleTypes: visibleTypes.value, selectedMachineryAccessoryCategories: selectedMachineryAccessoryCategories.value, accessoryFem: accessoryFem.value, accessoryDrive: accessoryDrive.value, accessoryVolt: accessoryVolt.value, accessoryWheelSize: accessoryWheelSize.value, accessoryComment: accessoryComment.value, accessoryProductCode: accessoryProductCode.value, accessoryNumericalFilters: accessoryNumericalFilters?.value, shouldOnlyIncludeItemsWithOffers: shouldOnlyIncludeItemsWithOffers?.value, pagination: pagination?.value, endOfClientOfToday: endOfToday() }),
        queryKey: ['accessories', 'offer', where, offerWhere, { status: 'approval' }, firstCalendarDay, lastCalendarDay, isIncludingOffers, visibleTypes, selectedMachineryAccessoryCategories, accessoryFem, pagination, accessoryNumericalFilters, accessoryDrive, accessoryWheelSize, accessoryVolt, accessoryComment, accessoryProductCode],
        meta: { errorKey: 'Kalender-Einträge für Anbau-Geräte' },
        refetchOnWindowFocus: false,
        refetchInterval: 2 * 60 * 1000,
        placeholderData: keepPreviousData,
      }),
      itemSet: (where: Where, firstCalendarDay: Ref<number>, lastCalendarDay: Ref<Date>, isIncludingOffers: Ref<boolean>, visibleTypes: Ref<OfferType[]>, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.calendar.getItemSet.query({ where: where.value, firstCalendarDay: new Date(firstCalendarDay.value), lastCalendarDay: lastCalendarDay.value, isIncludingOffers: isIncludingOffers.value, visibleTypes: visibleTypes.value, pagination: pagination?.value }),
        queryKey: ['itemSet', 'offer', where, firstCalendarDay, lastCalendarDay, isIncludingOffers, visibleTypes, pagination],
        meta: { errorKey: 'Sets' },
        refetchOnWindowFocus: false,
        refetchInterval: 2 * 60 * 1000,
      }),
      machineryAccessoryGroupedByCategory: (args: CalendarMachineryAccessoryGroupedByCategoryArgs, getAll?: boolean) => useQuery({
        queryFn: () => {
          if (!args.value) {
            return null
          }

          return $trpc.calendar.getMachineryAccessoriesGroupedByCategory.query({
            where: args.value.where,
            offerType: args.value.offerType,
            offerStartDay: new Date(args.value.obligationStartsAt),
            offerEndDay: args.value.obligationEndsAt ? new Date(args.value.obligationEndsAt) : undefined,
            machineryTypeId: args.value.machineryTypeId,
            selectedMachineryAccessoryCategories: args.value.selectedMachineryAccessoryCategories,
            getAll,
          })
        },
        queryKey: ['accessories', 'offer', args, getAll],
        meta: { errorKey: 'Kalender-Einträge für Anbau-Geräte' },
      }),
    },
    receptionMachinery: {
      all: () => useQuery({
        queryFn: () => {
          return $trpc.receptionMachinery.getAll.query({})
        },
        queryKey: ['receptionMachines'],
        meta: { errorKey: 'Empfangsgeräte' },
      }),
      allByType: (where: Where | Ref<undefined>, take: Ref<number>) => useQuery({
        queryFn: () => {
          if (!where.value) {
            return null
          }

          return $trpc.receptionMachinery.getAll.query({ where: where?.value, take: take.value })
        },
        queryKey: ['receptionMachines', where, take],
        meta: { errorKey: 'Empfangsgeräte' },
        refetchOnMount: 'always',
      }),
      byMachineryId: (id: Ref<string>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.receptionMachinery.getByMachineryId.query({ id: id.value })
        },
        queryKey: ['machines', 'receptionMachines', id],
        meta: { errorKey: 'Empfangsgerät' },
        refetchOnMount: 'always',
      }),
      byMachineryIdOrThrow: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.receptionMachinery.getByMachineryIdOrThrow.query({ id: id.value ?? '' })
        },
        queryKey: ['machines', 'receptionMachines', id],
        meta: { errorKey: 'Empfangsgerät' },
        refetchOnMount: 'always',
      }),
      byIdOrThrow: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.receptionMachinery.getByIdOrThrow.query({ id: id.value ?? '' })
        },
        queryKey: ['machines', 'receptionMachines', id],
        meta: { errorKey: 'Empfangsgerät' },
        refetchOnMount: 'always',
      }),
      specialEquipments: () => useQuery({
        queryFn: () => $trpc.receptionMachinery.getSpecialEquipments.query(),
        queryKey: ['specialEquipments'],
        meta: { errorKey: 'Spezialequipment' },
        refetchOnMount: 'always',
      }),
      tireTypes: () => useQuery({
        queryFn: () => $trpc.receptionMachinery.getTireTypes.query(),
        queryKey: ['tireTypes'],
        meta: { errorKey: 'Reifentypen' },
        refetchOnMount: 'always',
      }),
    },
    receptionMachineryHistory: {
      allForOverview: (receptionMachineryId: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!receptionMachineryId.value) {
            return []
          }

          return $trpc.receptionMachineryHistory.getAllForOverview.query({ receptionMachineryId: receptionMachineryId.value })
        },
        queryKey: ['receptionMachineryHistory', 'all', receptionMachineryId],
        meta: { errorKey: 'Empfangsverlauf' },
      }),
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.receptionMachineryHistory.getById.query({ id: id.value })
        },
        queryKey: ['receptionMachineryHistory', id],
        meta: { errorKey: 'Empfangsgerät' },
      }),
    },
    machineryType: {
      all: (where?: Where) => useQuery({
        queryFn: () => $trpc.machineryType.getAll.query({ where: where?.value }),
        queryKey: ['machineryTypes', where],
        meta: { errorKey: 'Gerätetypen' },
      }),
      byId: (id: Ref<string | undefined>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.machineryType.getById.query({ id: id.value ?? '' }),
        queryKey: ['machineryTypes', id],
        meta: { errorKey: 'Gerätetyp' },
        enabled,
      }),
      byIdOrThrow: (id: Ref<string | undefined>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.machineryType.getByIdOrThrow.query({ id: id.value })
        },
        queryKey: ['machineryTypes', id],
        meta: { errorKey: 'Gerätetyp' },
        enabled,
      }),
    },
    machineryAccessory: {
      countsByTargetState: (enabled: boolean) => useQuery({
        queryFn: () => $trpc.machineryAccessory.getCountsByTargetState.query(),
        queryKey: ['accessories', 'counts'],
        meta: { errorKey: 'Anbaugeräte-Zahlen' },
        enabled,
      }),
      all: (where?: Where, filterEligibleForTargetState?: Ref<MachineryAccessoryStatus>, datesForOverlapCheck?: Ref<DatesForOverlapCheck>) => useQuery({
        queryFn: () => $trpc.machineryAccessory.getAll.query({ where: where?.value, filterEligibleForTargetState: filterEligibleForTargetState?.value, datesForOverlapCheck: datesForOverlapCheck?.value }),
        queryKey: ['accessories', where, filterEligibleForTargetState, datesForOverlapCheck],
        meta: { errorKey: 'Anbaugeräte' },
      }),
      allForOverview: (where?: Where, filterEligibleForTargetState?: Ref<MachineryAccessoryStatus>, pagination?: Ref<Pagination>, hideAccessoriesWithActiveOrders?: Ref<boolean>, shouldExcludeBundledAccessories?: boolean, shouldExcludeAccessoriesWithAttachedMachinery?: boolean) => useQuery({
        queryFn: () => {
          const clientTodayInterval = hideAccessoriesWithActiveOrders?.value ? { start: startOfToday(), end: endOfToday() } : undefined

          return $trpc.machineryAccessory.getAllForOverview.query({ where: where?.value, filterEligibleForTargetState: filterEligibleForTargetState?.value, pagination: pagination?.value, clientTodayInterval, shouldExcludeBundledAccessories, shouldExcludeAccessoriesWithAttachedMachinery })
        },
        queryKey: ['accessories', filterEligibleForTargetState, where, pagination, hideAccessoriesWithActiveOrders, shouldExcludeBundledAccessories, shouldExcludeAccessoriesWithAttachedMachinery],
        meta: { errorKey: 'Anbaugeräte' },
        placeholderData: keepPreviousData,
      }),
      byId: (id: Ref<string | undefined>, enabled = true) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.machineryAccessory.getById.query({ id: id.value })
        },
        queryKey: ['accessories', id],
        meta: { errorKey: 'Anbaugerät' },
        enabled,
      }),
      soldMachineryAccessoriesByCustomerId: (id: Ref<undefined | string>, where: Where) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return []
          }

          return $trpc.machineryAccessory.getSoldMachineryAccessoriesByCustomerId.query({ id: id.value, where: where.value })
        },
        queryKey: ['accessories', 'soldMachineryAccessoriesByCustomerId', id, where],
        meta: { errorKey: 'Anbaugeräte' },
      }),
      selectedMachineryAccessoriesForOfferByIds: (ids: Ref<string[] | undefined>) => useQuery({
        queryFn: () => {
          if (!ids.value || ids.value.length === 0) {
            return []
          }

          return $trpc.machineryAccessory.getSelectedMachineryAccessoriesForOfferByIds.query({ ids: ids.value })
        },
        queryKey: ['accessories', 'selectedMachineryAccessoriesForOfferByIds', ids],
        meta: { errorKey: 'Anbaugeräte' },
      }),
      public: {
        byId: (id: Ref<string>) => useQuery({
          queryFn: () => $trpc.machineryAccessory.public.getById.query({ id: id.value }),
          queryKey: ['accessories', id, 'public'],
          meta: { errorKey: 'Anbaugeräte' },
        }),
      },
      compatibility: {
        getCompatibleAccessoryIds: (id: Ref<string | undefined>) => useQuery({
          queryFn: () => {
            if (!id.value) {
              return []
            }

            return $trpc.machineryAccessory.compatibility.getCompatibleAccessoryIds.query({ id: id.value })
          },
          queryKey: ['accessoryIds', 'compatibility', id],
          meta: { errorKey: 'Anbaugeräte' },
        }),
        compatibleAccessoriesForBundle: (where: Where, machineryAccessoryIds: Ref<string[]>) => useQuery({
          queryFn: () => $trpc.machineryAccessory.compatibility.getCompatibleAccessoriesForBundle.query({ where: where.value, machineryAccessoryIds: machineryAccessoryIds.value }),
          queryKey: ['accessories', 'bundles', where, machineryAccessoryIds],
          meta: { errorKey: 'Anbaugeräte' },
        }),
      },
    },
    machineryAccessoryBundle: {
      all: (where?: Where) => useQuery({
        queryFn: () => $trpc.machineryAccessory.bundle.getAll.query({ where: where?.value }),
        queryKey: ['accessoryBundles', where],
        meta: { errorKey: 'Lagertool-Bündel' },
      }),
      byMachineryAccessoryId: (id: string) => useQuery({
        queryFn: () => $trpc.machineryAccessory.bundle.getByMachineryAccessoryId.query({ id }),
        queryKey: ['accessoryBundles', id],
        meta: { errorKey: 'Lagertool-Bundel' },
      }),
    },
    customer: {
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.customer.getById.query({ id: id.value })
        },
        queryKey: ['customer', id, 'contactPersons'],
        meta: { errorKey: 'Kunden' },
      }),
      all: (where?: Where, queryOptions: Record<string, any> = {}) => useQuery({
        queryFn: () => $trpc.customer.getAll.query({ where: where?.value ?? {} }),
        queryKey: ['customer', where],
        meta: { errorKey: 'Kunden' },
        ...queryOptions,
      }),
      allOptions: () => useQuery({
        queryFn: () => $trpc.customer.getAllOptions.query(),
        queryKey: ['customer', 'allOptions'],
        meta: { errorKey: 'Kunden' },
      }),
      contactPersonOptionsById: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.customer.getContactPersonOptionsById.query({ id: id.value })
        },
        queryKey: ['customer', 'contactPersonOptionsById', id],
        meta: { errorKey: 'Kunden' },
      }),
      allForSelect: () => useQuery({
        queryFn: () => $trpc.customer.getAllForSelect.query(),
        queryKey: ['customer', 'allForSelect'],
        meta: { errorKey: 'Kunden' },
      }),
    },
    otherInvoiceRecipient: {
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.otherInvoiceRecipient.getById.query({ id: id.value })
        },
        queryKey: ['otherInvoiceRecipient', 'byId', id],
        meta: { errorKey: 'Abweichender Rechnungsempfänger' },
      }),
      allByCustomerId: (customerId: Ref<string | undefined | null>, where?: Where) => useQuery({
        queryFn: () => {
          if (!customerId.value) {
            return []
          }
          // We do not need customerId in where clause as we get this value separately
          return $trpc.otherInvoiceRecipient.getAll.query({ where: { ...where?.value, customerId: customerId.value } })
        },
        queryKey: ['otherInvoiceRecipient', 'allByCustomerId', customerId, where],
        meta: { errorKey: 'Abweichender Rechnungsempfänger' },
      }),
      all: (where?: Where) => useQuery({
        queryFn: () => {
          return $trpc.otherInvoiceRecipient.getAll.query({ where: where?.value ?? {} })
        },
        queryKey: ['otherInvoiceRecipient', where],
        meta: { errorKey: 'Abweichender Rechnungsempfänger' },
      }),
    },
    positionTemplate: {
      all: (where?: MaybeRefWhere, titleLocalesToGet: SupportedLocale[] = ['de']) => useQuery({
        queryFn: () => $trpc.positionTemplate.getAll.query({ where: where ? unref(where) : undefined, titleLocalesToGet }),
        queryKey: ['positionTemplates', where, titleLocalesToGet],
        meta: { errorKey: 'Positionen' },
      }),
    },
    offer: {
      all: (where: MaybeRefWhere, pagination?: Ref<Pagination>, sorters?: ComputedRef<Record<string, 'asc' | 'desc'>[]>) => useQuery({
        queryFn: () => $trpc.offer.getAll.query({ where: unref(where), pagination: pagination?.value, sorters: sorters?.value }),
        queryKey: ['offer', where, pagination, sorters],
        meta: { errorKey: 'Angebote' },
        placeholderData: keepPreviousData,
      }),
      overlappingOfferPositionsByOfferId: (offerData: Ref<OfferDataForOverlapCheck | null>) => useQuery({
        queryFn: () => {
          const offerDataUnref = unref(offerData)

          if (!offerDataUnref) {
            return {} as Record<string, string[]>
          }

          const { type, obligationStartsAt, obligationEndsAt, positions } = offerDataUnref

          // Special offers don't have machinery or accessories
          if (type === 'special' || positions.length === 0) {
            return {} as Record<string, string[]>
          }

          // Invalid rental duration: no end date, or negative duration
          if (type === 'rental' && (!obligationEndsAt || isBefore(obligationEndsAt, obligationStartsAt))) {
            return {} as Record<string, string[]>
          }

          return $trpc.offer.getOverlappingOfferPositions.query(offerDataUnref)
        },
        queryKey: ['offer', offerData],
        meta: { errorKey: 'Angebote für Überlappungs-Check' },
      }),
      byId: (id: MaybeRef<string | undefined>, include?: ApiInputOfferGetById['include'], preventGarbageCollection = false, enabled = true) => useQuery({
        queryFn: () => {
          const idValue = unref(id)
          if (!idValue) {
            return null
          }
          return $trpc.offer.getById.query({ id: idValue, include })
        },
        queryKey: ['offer', id, preventGarbageCollection],
        meta: { errorKey: 'Angebot' },
        refetchOnMount: 'always',
        gcTime: preventGarbageCollection ? Number.POSITIVE_INFINITY : undefined,
        enabled,
      }),
      serviceProjectsForExternalStakeholders: (where: MaybeRefWhere, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.offer.getServiceProjectsForExternalStakeholders.query({ where: unref(where), pagination: unref(pagination) }),
        queryKey: ['offer', 'external', where, pagination],
        meta: { errorKey: 'Dienstleistung' },
      }),
      serviceProjectForExternalStakeholders: (id: string) => useQuery({
        queryFn: () => $trpc.offer.getServiceProjectForExternalStakeholders.query({ id }),
        queryKey: ['offer', 'external', id],
        meta: { errorKey: 'Dienstleistung' },
      }),
      byIdOnlyPositions: (id: MaybeRef<string | undefined>) => useQuery({
        queryFn: () => {
          const idValue = unref(id)
          if (!idValue) {
            return null
          }
          return $trpc.offer.getPositionsOnly.query({ id: idValue })
        },
        queryKey: ['offer', 'offerPosition', id],
        meta: { errorKey: 'Angebots-Positionen' },
        refetchOnMount: 'always',
      }),
      byIdForStatusTimeline: (id: string | undefined) => useQuery({
        queryFn: () => {
          if (!id) {
            return null
          }
          return $trpc.offer.getByIdForStatusTimeline.query({ id })
        },
        queryKey: ['offer', 'statusTimeline', id],
        meta: { errorKey: 'Status-Timeline' },
        refetchOnMount: 'always',
      }),
      compareReceptionMachinery: (id: MaybeRef<string | undefined>, machineryPosition: Ref<Extract<OfferPositionCreateOrUpdate, { type: 'machinery' }> | undefined>) => useQuery({
        queryFn: () => {
          const idValue = unref(id)

          if (!idValue || !machineryPosition.value) {
            return null
          }

          // Ensure that receptionMachinery exsit in machineryPosition
          const machineryPositionWithReceptionMachinery = machineryPosition.value.receptionMachinery
            ? {
                ...machineryPosition.value,
                receptionMachinery: machineryPosition.value.receptionMachinery,
              }
            : undefined
          if (!machineryPositionWithReceptionMachinery) {
            return null
          }

          return $trpc.offer.compareReceptionMachinery.query({ id: idValue, machineryPosition: machineryPositionWithReceptionMachinery })
        },
        queryKey: ['receptionMachines', id, machineryPosition],
        meta: { errorKey: 'Maschineninformationen-Vergleich' },
      }),
      public: {
        byCuid: (cuid: MaybeRef<string | undefined>) => useQuery({
          queryFn: () => {
            const cuidValue = unref(cuid)
            if (!cuidValue) {
              return null
            }
            return $trpc.offer.public.getByCuid.query({ cuid: cuidValue })
          },
          queryKey: ['offer', 'public', 'cuid', cuid],
          meta: { errorKey: 'Angebot' },
        }),
        completionPhotosById: (id: Ref<string | undefined>) => useQuery({
          queryFn: () => {
            if (!id.value) {
              return []
            }

            return $trpc.offer.public.getCompletionPhotosById.query(id.value)
          },
          queryKey: ['offer', 'public', 'completionPhotos', id],
          meta: { errorKey: 'Angebot' },
        }),
      },
      serviceProjectCounts: (enabled: boolean) => useQuery({
        queryFn: () => $trpc.offer.getServiceProjectCounts.query(),
        queryKey: ['offer', 'counts'],
        enabled,
      }),
    },
    user: {
      all: (where: MaybeRefWhere, pagination?: Ref<Pagination>, selectedRoles?: Ref<UserRole[]>, showArchived?: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.user.getAll.query({ where: unref(where), pagination: pagination?.value, selectedRoles: selectedRoles?.value, showArchived: showArchived?.value }),
        queryKey: ['users', where, pagination, selectedRoles, showArchived],
        meta: { errorKey: 'Nutzer' },
        placeholderData: keepPreviousData,
      }),
      drivers: (where?: Where) => useQuery({
        queryFn: () => $trpc.user.getDrivers.query({ where: where?.value }),
        queryKey: ['users', where],
        meta: { errorKey: 'Nutzer' },
      }),
      customerUsers: (customerId: Ref<string | null>, where?: Where) => useQuery({
        queryFn: () => {
          if (!customerId.value) {
            return []
          }

          const whereValue = defu({ contactPerson: { customerId: customerId.value } }, where?.value)
          return $trpc.user.getCustomerUsers.query({ where: whereValue })
        },
        queryKey: ['users', 'customerUsers', customerId, where],
        meta: { errorKey: 'Nutzer' },
      }),
      possibleOfferContacts: () => useQuery({
        queryFn: () => $trpc.user.getPossibleOfferContacts.query(),
        queryKey: ['users'],
        meta: { errorKey: 'Nutzer' },
      }),
      getDistinctOfferMembershipRoles: (enabled: MaybeRef<boolean> = true) => useQuery({
        queryFn: () => $trpc.user.getDistinctOfferMembershipRoles.query(),
        queryKey: ['userOfferMembership'],
        meta: { errorKey: 'Mitglied(er)' },
        enabled,
      }),
      storagePositionStoredByOptions: (enabled: boolean) => useQuery({
        queryFn: () => $trpc.user.getStoragePositionStoredByOptions.query(),
        queryKey: ['users', 'storagePositionStoredBy'],
        meta: { errorKey: 'Nutzer' },
        enabled,
      }),
      storagePositionCreatedByOptions: (enabled: boolean, customerId: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!customerId.value) {
            return []
          }

          return $trpc.user.getStoragePositionCreatedByOptions.query({ customerId: customerId.value })
        },
        queryKey: ['users', 'storagePositionCreatedBy', customerId],
        meta: { errorKey: 'Nutzer' },
        enabled,
      }),
      offerPositionIssuedOrReturnedByOptions: (view: Ref<LogisticsView>, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.user.getOfferPositionIssuedOrReturnedByOptions.query({ view: view.value }),
        queryKey: ['users', 'offerPositionIssuedOrReturnedByOptions', view],
        meta: { errorKey: 'Nutzer' },
        enabled,
      }),
    },
    account: {
      getCurrentAccountData: () => useQuery({
        queryFn: () => $trpc.account.getCurrentAccountData.query(),
        queryKey: ['account'],
        meta: { errorKey: 'Account-Meta-Daten' },
      }),
    },
    logisticsTask: {
      all: (where?: Where, orderBy?: z.infer<typeof logisticsTasksSortSchema>, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.logisticsTask.getAll.query({ where: where?.value, orderBy }),
        queryKey: ['logisticsTask', where, orderBy],
        meta: { errorKey: 'Logistik' },
        enabled,
      }),
      allForOverview: (where?: Where, orderBy?: z.infer<typeof logisticsTasksSortSchema>, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.logisticsTask.getAllForOverview.query({ where: where?.value, orderBy, pagination: pagination?.value }),
        queryKey: ['logisticsTask', where, orderBy, pagination],
        meta: { errorKey: 'Logistik' },
        placeholderData: keepPreviousData,
      }),
      withActions: () => useQuery({
        queryFn: () => $trpc.logisticsTask.getAll.query({ where: { actions: { contains: ',' } } }),
        queryKey: ['logisticsTask'],
        meta: { errorKey: 'Logistik' },
      }),
      byIdOrThrow: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.logisticsTask.getByIdOrThrow.query({ id: id.value })
        },
        queryKey: ['logisticsTask', id],
        meta: { errorKey: 'Logistik' },
      }),
      byCuidOrThrow: (cuid: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!cuid.value) {
            return null
          }
          return $trpc.logisticsTask.getByCuidOrThrow.query({ cuid: cuid.value })
        },
        queryKey: ['logisticsTask', cuid],
        meta: { errorKey: 'Logistik' },
      }),
      byIdForStatusTimeline: (id: string | undefined) => useQuery({
        queryFn: () => {
          if (!id) {
            return null
          }
          return $trpc.logisticsTask.getByIdForStatusTimeline.query({ id })
        },
        queryKey: ['logisticsTask', 'statusTimeline', id],
        meta: { errorKey: 'Logistik' },
      }),
    },
    logisticsPlanning: {
      searchTasks: (where?: Where, orderBy?: z.infer<typeof logisticsTasksSortSchema>) => useQuery({
        queryFn: () => {
          const whereValue = where?.value

          // Do not trigger search if nothing was entered
          if (!whereValue || Object.keys(whereValue).length === 0) {
            return []
          }

          return $trpc.logisticsPlanning.searchTasks.query({ where: whereValue, orderBy })
        },
        queryKey: ['logisticsTask', where, orderBy],
        meta: { errorKey: 'Logistik' },
        refetchOnWindowFocus: false,
        refetchInterval: false,
        refetchOnMount: false,
        staleTime: 1000,
      }),
      getDataForDispatching: (selectedStartDate: Ref<number>, daysToLoad?: Ref<number>, withCanceledTasks?: Ref<boolean>, showCustomerDoesTask?: Ref<boolean>, showFitterDoesTask?: Ref<boolean>, showTransportationCompanyDoesTask?: Ref<boolean>, enableFastAutoRefresh?: Ref<boolean>, selectedDeliveryVehicleIds?: Ref<string[]>) => useQuery({
        queryFn: () => $trpc.logisticsPlanning.getDataForDispatching.query({ startOfSelectedStartDate: startOfDay(selectedStartDate.value), endOfSelectedStartDate: endOfDay(selectedStartDate.value), daysToLoad: daysToLoad?.value, withCanceledTasks: withCanceledTasks?.value, showCustomerDoesTask: showCustomerDoesTask?.value, showFitterDoesTask: showFitterDoesTask?.value, showTransportationCompanyDoesTask: showTransportationCompanyDoesTask?.value, assignedDeliveryVehicleIds: selectedDeliveryVehicleIds?.value?.length ? selectedDeliveryVehicleIds?.value : undefined }),
        queryKey: ['logisticsTask', selectedStartDate, daysToLoad, withCanceledTasks, showCustomerDoesTask, showFitterDoesTask, showTransportationCompanyDoesTask, enableFastAutoRefresh, selectedDeliveryVehicleIds],
        meta: { errorKey: 'Fahrzeug-Touren' },
        refetchOnWindowFocus: false,
        refetchInterval: computed(() => {
          if (enableFastAutoRefresh?.value) {
            return 5 * 1000
          }

          return 1 * 60 * 1000
        }),
        placeholderData: keepPreviousData,
      }),
    },
    deliveryVehicle: {
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => $trpc.deliveryVehicle.getById.query(id.value ?? ''),
        queryKey: ['deliveryVehicles', 'unique', id],
      }),
      byIdOrThrow: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.deliveryVehicle.getByIdOrThrow.query(id.value)
        },
        queryKey: ['deliveryVehicles', 'unique', id],
      }),
      all: (where?: Where) => useQuery({
        queryFn: () => $trpc.deliveryVehicle.getAll.query({ where: where?.value ?? {} }),
        queryKey: ['deliveryVehicles', 'all', where],
        meta: { errorKey: 'Lieferfahrzeuge' },
      }),
    },
    machineryDrive: {
      all: () => useQuery({
        queryFn: () => $trpc.machineryDrive.getAll.query(),
        queryKey: ['machineryDrives', 'all'],
        meta: { errorKey: 'Antrieb' },
      }),
    },
    offerPosition: {
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.offerPosition.getById.query({ id: id.value })
        },
        queryKey: ['offerPosition', 'unique', id],
        meta: { errorKey: 'Angebotsposition' },
      }),
      byIdForStatusTimeline: (id: string | undefined) => useQuery({
        queryFn: () => {
          if (!id) {
            return null
          }
          return $trpc.offerPosition.getByIdForStatusTimeline.query({ id })
        },
        queryKey: ['offerPosition', 'statusTimeline', id],
        meta: { errorKey: 'Angebotsposition' },
      }),
      all: (where?: Where, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.offerPosition.getAll.query({ where: where?.value }),
        queryKey: ['offerPosition', 'all', where],
        meta: { errorKey: 'Angebotsposition' },
        enabled,
      }),
      allSpecialPositions: (offerId: Ref<string | undefined>, logisticsTaskId: Ref<string | undefined>, doRefetchOnWindowFocus = true) => useQuery({
        queryFn: () => {
          if (!offerId.value || !logisticsTaskId.value) {
            return []
          }

          return $trpc.offerPosition.special.getAll.query({ offerId: offerId.value, logisticsTaskId: logisticsTaskId.value })
        },
        queryKey: ['offerPosition', offerId, doRefetchOnWindowFocus],
        refetchOnWindowFocus: doRefetchOnWindowFocus,
        meta: { errorKey: 'Sonderartikel' },
      }),
      soldMachineryPosition: (machineryId: string, soldAt?: Date | null, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.offerPosition.getSoldMachineryPosition.query({ machineryId, soldAt }),
        queryKey: ['offerPosition', machineryId, soldAt],
        meta: { errorKey: 'Angebotsposition' },
        enabled,
      }),
    },
    invoice: {
      all: (where?: Where) => useQuery({
        queryFn: () => $trpc.invoice.getAll.query({ where: where?.value }),
        queryKey: ['invoice', where],
        meta: { errorKey: 'Rechnungen' },
      }),
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.invoice.getById.query({ id: id.value })
        },
        queryKey: ['invoice', 'unique', id],
        meta: { errorKey: 'Rechnung' },
      }),
      allForOverview: (where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.invoice.getAllForOverview.query({ where: where?.value, pagination: pagination?.value }),
        queryKey: ['invoice', where, pagination],
        meta: { errorKey: 'Rechnungen' },
        placeholderData: keepPreviousData,
      }),
      getToCreateInvoice: (offerId: Ref<string | undefined>, isFullPayment: Ref<boolean>) => useQuery({
        queryFn: () => {
          if (!offerId.value) {
            return null
          }

          return $trpc.invoice.getToCreateInvoice.query({ offerId: offerId.value, isFullPayment: isFullPayment.value })
        },
        queryKey: ['invoice', 'offerPosition', offerId, isFullPayment],
        meta: { errorKey: 'Angebotsposition' },
      }),
      offersForPreparation: (preset: ComputedRef<ApiInputInvoiceGetAllOffersForPreparation['preset'] | undefined>, where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => {
          if (!preset.value) {
            return null
          }
          return $trpc.invoice.offersForPreparation.query({ preset: preset.value, where: where?.value, pagination: pagination?.value })
        },
        queryKey: ['offer', 'invoice', 'preparation', preset, where, pagination],
        meta: { errorKey: 'Angebote zur Rechnungsvorbereitung' },
        placeholderData: keepPreviousData,
      }),
      offerForInvoiceById: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.invoice.offerForInvoiceById.query({ id: id.value })
        },
        queryKey: ['offer', 'invoice', 'unique', id],
        meta: { errorKey: 'Angebot zur Rechnungsvorbereitung' },
      }),
    },
    comment: {
      allByTypeAndEntityId: (filters: MaybeRef<{ type: CommentType, entityId: string }>) => useQuery({
        queryFn: () => {
          const { type, entityId } = unref(filters)
          const entityIdKey = commentConfigs[type].commentTableRelationFieldName

          return $trpc.comment.getAll.query({ where: { type, [entityIdKey]: entityId } })
        },
        queryKey: ['comment', 'byTypeAndEntityId', filters],
        meta: { errorKey: 'Kommentare' },
      }),
      countAllByTypeAndEntityId: (filters: MaybeRef<{ type?: CommentType, entityId?: string }>) => useQuery({
        queryFn: () => {
          const { type, entityId } = unref(filters)
          if (!type || !entityId) {
            return 0
          }

          const entityIdKey = commentConfigs[type].commentTableRelationFieldName
          return $trpc.comment.getAllCount.query({ where: { type, [entityIdKey]: entityId } })
        },
        queryKey: ['comment', 'byTypeAndEntityId', 'count', filters],
        meta: { errorKey: 'Kommentar-Zahl' },
      }),
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (id.value === undefined) {
            return null
          }

          return $trpc.comment.getById.query({ id: id.value })
        },
        queryKey: ['comment', 'byId', id],
        meta: { errorKey: 'Kommentar' },
      }),
    },
    shopping: {
      allAccessories: (enabled: Ref<boolean>, offerStartDay: Ref<Date>, offerEndDay: Ref<Date | undefined>, where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.shopping.accessoryAvailabilities.allAccessories.query({ where: where?.value, offerStartDay: offerStartDay.value, offerEndDay: offerEndDay.value, pagination: pagination?.value }),
        queryKey: ['shopping', 'availability', 'accessory', where, offerStartDay, offerEndDay, pagination],
        meta: { errorKey: 'Anbaugeräte' },
        enabled,
      }),
      accessoryAvailabilitiesByMachineryId: <T extends boolean>(machineryId: string | Ref<string | undefined>, accessoryCategoryToCheck: Ref<null | MachineryAccessoryCategory>, firstCalendarDay: Ref<Date>, lastCalendarDay: Ref<Date | undefined>, countOnly: T, offerStatus: Ref<OfferStatus | undefined>, compatibleAccessoryIds?: Ref<string[]>) => useQuery({
        queryFn: async () => {
          const machineryIdValue = unref(machineryId)

          const res = await $trpc.shopping.accessoryAvailabilities.byMachineryId.query({
            machineryId: machineryIdValue,
            accessoryCategoryToCheck: accessoryCategoryToCheck.value,
            countOnly,
            firstCalendarDay: firstCalendarDay.value,
            lastCalendarDay: lastCalendarDay.value,
            offerStatus: offerStatus.value,
            compatibleAccessoryIds: compatibleAccessoryIds?.value,
          })

          // AFAIK it is not possible to get trpc generic returns based on the value of `countOnly` (https://github.com/trpc/trpc/discussions/2150). In order to avoid code duplication we extract the right type here: If `countOnly = true` we extract the `number` variant, otherwise we extract the `Object` variant
          type ReturnType = T extends true ? Extract<typeof res, { attached: number }> : Extract<typeof res, { attached: { id: string }[] }>
          return res as ReturnType
        },
        queryKey: ['shopping', 'availability', machineryId, accessoryCategoryToCheck, (countOnly satisfies boolean), firstCalendarDay, lastCalendarDay, offerStatus, compatibleAccessoryIds],
        meta: { errorKey: 'Anbaugeräte-Verfügbarkeiten' },
      }),
      checkSpecificMachineryAccessoryCombinations: (machineryId: string | Ref<string | undefined>, accessoryIdsToCheck: Ref<string[]>) => useQuery({
        queryFn: () => {
          const machineryIdValue = unref(machineryId)
          if (!machineryIdValue) {
            return Object.fromEntries(accessoryIdsToCheck.value.map(id => [id, false]))
          }

          return $trpc.shopping.accessoryAvailabilities.checkSpecificMachineryAccessoryCombinations.query({ machineryId: machineryIdValue, machineryAccessoryIds: accessoryIdsToCheck.value })
        },
        queryKey: ['shopping', 'availability', machineryId, accessoryIdsToCheck],
        meta: { errorKey: 'Anbaugeräte-Verfügbarkeiten' },
      }),
      getAttachedMachineryAccessories: (machineryId: string | Ref<string | undefined>) => useQuery({
        queryFn: () => {
          const machineryIdValue = unref(machineryId)

          if (!machineryIdValue) {
            return []
          }

          return $trpc.shopping.accessoryAvailabilities.getAttachedMachineryAccessories.query({ machineryId: machineryIdValue })
        },
        queryKey: ['shopping', 'availability', machineryId],
        meta: { errorKey: 'Verbaute Anbaugeräte' },
      }),
      getCompatibleAccessories: (machineryId: MaybeRef<string | undefined>, accessoryCategories?: Ref<MachineryAccessoryCategory[] | undefined>, where?: Where, shouldIncludeAttachedAccessories?: MaybeRef<boolean>) => useQuery({
        queryFn: () => {
          const machineryIdValue = unref(machineryId)
          const shouldIncludeAttachedAccessoriesValue = unref(shouldIncludeAttachedAccessories)

          if (!machineryIdValue) {
            return []
          }

          return $trpc.shopping.accessoryAvailabilities.getCompatibleAccessories.query({ machineryId: machineryIdValue, accessoryCategories: accessoryCategories?.value, where: where?.value, shouldIncludeAttachedAccessories: shouldIncludeAttachedAccessoriesValue })
        },
        queryKey: ['shopping', 'availability', machineryId, accessoryCategories, where, shouldIncludeAttachedAccessories],
        meta: { errorKey: 'Kompatible Anbaugeräte' },
      }),
    },
    internalCostPosition: {
      all: (where?: Where, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.internalCostPosition.getAll.query({ where: where?.value }),
        queryKey: ['internalCostPosition', where],
        meta: { errorKey: 'Interne Kosten' },
        enabled,
      }),
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (id.value === undefined) {
            return null
          }

          return $trpc.internalCostPosition.getById.query({ id: id.value ?? '' })
        },
        queryKey: ['internalCostPosition', id],
        meta: { errorKey: 'Interne Kosten' },
      }),
    },
    defect: {
      all: (where?: Where) => useQuery({
        queryFn: () => $trpc.defect.getAll.query({ where: where?.value }),
        queryKey: ['defect', where],
        meta: { errorKey: 'Defekt' },
      }),
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (id.value === undefined) {
            return null
          }

          return $trpc.defect.getById.query({ id: id.value })
        },
        queryKey: ['defect', 'byId', id],
        meta: { errorKey: 'Defekt' },
      }),
    },
    modelChanges: {
      all: (where: Ref<Prisma.ModelChangesWhereInput>) => useQuery({
        queryFn: () => $trpc.modelChanges.getAll.query({ where: where.value }),
        queryKey: ['modelChanges', where],
        meta: { errorKey: 'Geschichte der Aktualisierung' },
      }),
    },
    itemSet: {
      all: (where: Ref<_Where | null>, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => {
          if (!where.value) {
            return null
          }
          return $trpc.itemSet.getAll.query({ where: where.value, pagination: pagination?.value })
        },
        queryKey: ['itemSet', where, pagination],
        meta: { errorKey: 'Set' },
      }),
      allWithOngoingOfferPositions: (where: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.itemSet.getAllWithOngoingOfferPositions.query({ where: where.value, pagination: pagination?.value, today: startOfToday() }),
        queryKey: ['itemSet', 'withOngoingOfferPositions', where, pagination],
        meta: { errorKey: 'Set' },
      }),
      byId: (id: Ref<undefined | string>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.itemSet.getById.query({ id: id.value })
        },
        queryKey: ['itemSet', id],
        meta: { errorKey: 'Set' },
      }),
      byIdWithOngoingOfferPositions: (id: Ref<undefined | string>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.itemSet.getByIdWithOngoingOfferPositions.query({ id: id.value, today: startOfToday() })
        },
        queryKey: ['itemSet', 'withOngoingOfferPositions', id],
        meta: { errorKey: 'Set' },
      }),
      public: {
        byId: (id: Ref<string>) => useQuery({
          queryFn: () => $trpc.itemSet.public.getById.query({ id: id.value }),
          queryKey: ['itemSet', id],
          meta: { errorKey: 'Set' },
        }),
      },
    },
    task: {
      // TODO: Remove `enabled` (if desired) once `'ext-storage-user'` roles are defined
      // https://github.com/sidestream-tech/hanselmann-os/issues/2355
      countsAssignedToUser: (enabled?: boolean) => useQuery({
        queryFn: () => $trpc.userTask.getMyTaskCounts.query(),
        queryKey: ['task', 'counts', enabled],
        meta: { errorKey: 'Aufgaben-Anzahl' },
        enabled,
      }),
      byId: (input: MaybeRef<{ id?: string }>) => useQuery({
        queryFn: async () => {
          const id = unref(input).id
          if (!id) {
            return null
          }

          const result = await $trpc.userTask.getTasks.query({ id })

          if (result.length === 1) {
            return result[0]
          }

          return null
        },
        queryKey: ['task', input],
        meta: { errorKey: 'Aufgabe(n)' },
      }),
      getTaskPermissionsForUser: (id: MaybeRef<string>) => useQuery({
        queryFn: () => $trpc.userTask.getTaskPermissionsForUser.query({ id: unref(id) }),
        queryKey: ['task', id],
        meta: { errorKey: 'Rechte' },
      }),
      allTasksAsCalendarEvents: (input: MaybeRef<ApiTaskGetAllInput>) => useQuery({
        queryFn: () => $trpc.userTask.getTasksAsCalendarEvents.query(unref(input)),
        queryKey: ['task', 'calendar', input],
        meta: { errorKey: 'Aufgabe(n)' },
      }),
      allOfferServiceProjectsAsCalendarEvents: (isInternalProject?: boolean, enabled?: boolean) => useQuery({
        queryFn: () => $trpc.userTask.getOfferServiceProjectsAsCalendarEvents.query({ isInternalProject }),
        queryKey: ['offer', isInternalProject],
        meta: { errorKey: 'Dienstleistungs-Projekte' },
        enabled,
      }),
      getOfferIdsUserHasAccessTo: (enabled?: boolean) => useQuery({
        queryFn: () => $trpc.userTask.getOfferIdsUserHasAccessTo.query(),
        queryKey: ['task', 'calendar'],
        meta: { errorKey: 'Auftrag-IDs' },
        enabled,
      }),
      getUsersUserCanAssignToTask: (input?: ComputedRef<{ relatedOfferId?: string }>) => useQuery({
        queryFn: () => $trpc.userTask.getUsersUserCanAssignToTask.query(unref(input)),
        queryKey: ['task', 'calendar', 'users', input],
        meta: { errorKey: 'Nutzer' },
      }),
    },
    reporting: {
      getChartRevenueMachinery: (duration: MaybeRef<[number, number] | null>, machineryId?: MaybeRef<string | undefined>, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration
          return $trpc.reporting.getChartRevenueMachinery.query({ startDate: new Date(startTime), endDate: new Date(endTime), machineryId: unref(machineryId) })
        },
        queryKey: ['statistics', 'machinery-revenue', duration, machineryId],
        meta: { errorKey: 'Maschinen-Umsätze' },
        enabled,
      }),
      getChartRevenueMachineryAccessory: (duration: MaybeRef<[number, number] | null>, fromItemSet: boolean, machineryAccessoryId?: MaybeRef<string | undefined>, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration
          return $trpc.reporting.getChartRevenueMachineryAccessory.query({ startDate: new Date(startTime), endDate: new Date(endTime), fromItemSet, machineryAccessoryId: unref(machineryAccessoryId) })
        },
        queryKey: ['statistics', 'machinery-accessory-revenue', duration, fromItemSet, machineryAccessoryId],
        meta: { errorKey: 'Lagertool-Umsätze' },
        enabled,
      }),
      getChartRevenueInvoices: (duration: MaybeRef<[number, number] | null>, showLine: Ref<boolean>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration
          return $trpc.reporting.getChartRevenueInvoices.query({ startDate: new Date(startTime), endDate: new Date(endTime), showLine: showLine.value })
        },
        queryKey: ['statistics', 'invoices-revenue', duration, showLine],
        meta: { errorKey: 'Rechnungs-Umsätze' },
        enabled,
      }),
      getChartIssuanceOrReturnedPerDay: (duration: MaybeRef<[number, number] | null>, type: 'issued' | 'returned', enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration
          return $trpc.reporting.getChartIssuanceOrReturnedPerDay.query({ startDate: new Date(startTime), endDate: new Date(endTime), type })
        },
        queryKey: ['statistics', type, duration],
        meta: { errorKey: 'Ausgaben und Rücknahmen' },
        enabled,
      }),
      getChartOffersAndOrders: (duration: MaybeRef<[number, number] | null>, offerType: OfferType, offerStatus?: Ref<OfferStatus | 'all'>, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration
          return $trpc.reporting.getChartOffersAndOrders.query({
            startDate: new Date(startTime),
            endDate: new Date(endTime),
            offerType,
            offerStatus: offerStatus?.value === 'all' ? undefined : offerStatus?.value,
          })
        },
        queryKey: ['statistics', 'offers-and-orders', duration, offerType, offerStatus],
        meta: { errorKey: 'Ausgaben und Rücknahmen' },
        enabled,
      }),
      getChartLogisticsTasksByDriver: (duration: MaybeRef<[number, number] | null>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration
          return $trpc.reporting.getChartLogisticsTasksByDriver.query({ startDate: new Date(startTime), endDate: new Date(endTime) })
        },
        queryKey: ['statistics', 'logistics-tasks--by-driver', duration],
        meta: { errorKey: 'Ausgaben und Rücknahmen' },
        enabled,
      }),
      getChartOpenTasks: (enabled: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.reporting.getChartOpenTasks.query(),
        queryKey: ['statistics', 'tasks'],
        meta: { errorKey: 'Ausgaben und Rücknahmen' },
        enabled,
      }),
      getChartRevenueCustomer: (duration: Ref<[number, number] | null>, customerId: Ref<string | undefined>, offerTypes?: Ref<OfferType[] | undefined>, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = duration.value
          const unrefedCustomerId = customerId.value
          const unrefedOfferTypes = offerTypes?.value

          if (!unrefedDuration || !unrefedCustomerId || !unrefedOfferTypes?.length) {
            return null
          }

          const [startTime, endTime] = unrefedDuration

          return $trpc.reporting.getChartRevenueCustomer.query({ startDate: new Date(startTime), endDate: new Date(endTime), customerId: unrefedCustomerId, offerTypes: unrefedOfferTypes })
        },
        queryKey: ['statistics', 'customer-revenue', duration, customerId, offerTypes],
        meta: { errorKey: 'Kunden-Umsätze' },
        enabled,
      }),
      getChartInvoiceCount: (duration: Ref<[number, number] | null>, customerId: Ref<string | undefined>, invoiceType: Ref<InvoiceType[] | undefined>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration
          return $trpc.reporting.getChartInvoiceCount.query({ startDate: new Date(startTime), endDate: new Date(endTime), customerId: customerId.value, invoiceType: unref(invoiceType) })
        },
        queryKey: ['statistics', 'invoice-count', invoiceType, duration, customerId],
        meta: { errorKey: 'Anzahl-Rechnungen' },
        enabled,
      }),
      getDieselTotalQuantity: (duration: Ref<[number, number] | null>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = duration.value

          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration
          return $trpc.reporting.getDieselTotalQuantity.query({ startDate: new Date(startTime), endDate: new Date(endTime) })
        },
        queryKey: ['statistics', 'diesel-quantity-and-price', duration],
        meta: { errorKey: 'Diesel-Umsatz' },
        enabled,
      }),
      getChartInvoicedPollutionCosts: (duration: Ref<[number, number] | null>, pollution: Ref<LogisticsVisualDetailsPollution | null>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration

          return $trpc.reporting.getChartInvoicedPollutionCosts.query({
            startDate: new Date(startTime),
            endDate: new Date(endTime),
            pollution: pollution.value,
          })
        },
        queryKey: ['statistics', 'invoiced-pollution-costs', duration, pollution],
        meta: { errorKey: 'Reinigungs-Kosten' },
        enabled,
      }),
      getChartTankFillingOnIssuance: (duration: Ref<[number, number] | null>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          if (!unrefedDuration) {
            return null
          }

          const [startTime, endTime] = unrefedDuration

          return $trpc.reporting.getChartTankFillingOnIssuance.query({
            startDate: new Date(startTime),
            endDate: new Date(endTime),
          })
        },
        queryKey: ['statistics', 'tank-filling-on-issuance', duration],
        meta: { errorKey: 'Diesel-Verbrauch' },
        enabled,
      }),
      getChartAverageRentalDuration: (duration: Ref<[number, number] | null>, customerId: Ref<string | undefined>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const unrefedDuration = unref(duration)
          const unrefedCustomerId = unref(customerId)

          if (!unrefedDuration || !unrefedCustomerId) {
            return null
          }

          const [startTime, endTime] = unrefedDuration

          return $trpc.reporting.getChartAverageRentalDuration.query({
            startDate: new Date(startTime),
            endDate: new Date(endTime),
            customerId: unrefedCustomerId,
          })
        },
        queryKey: ['statistics', 'average-rental-duration', duration, customerId],
        meta: { errorKey: 'Mietdauer' },
        enabled,
      }),
    },
    userOfferMembership: {
      allMembershipsForOffer: (offerId: MaybeRef<string | undefined>, enabled?: boolean) => useQuery({
        queryFn: () => {
          const unrefOfferId = unref(offerId)

          if (!unrefOfferId) {
            return []
          }

          return $trpc.userOfferMembership.getAllMembershipsForOffer.query({ offerId: unrefOfferId })
        },
        queryKey: ['userOfferMembership', 'existingMemberships', offerId],
        meta: { errorKey: 'Mitglied(er)' },
        enabled,
      }),
      getUsersUserCanAddToOffer: (offerId: MaybeRef<string | undefined>) => useQuery({
        queryFn: () => {
          const id = unref(offerId)
          if (!id) {
            return []
          }
          return $trpc.userOfferMembership.getUsersUserCanAddToOffer.query({ offerId: id })
        },
        queryKey: ['userOfferMembership', 'potentialUsersToAdd', offerId],
        meta: { errorKey: 'Mitglied(er)' },
      }),
    },
    freelancerSales: {
      getMachineryReleasedForFreelancerConditions: (where: Ref<FreelancerSalesGetMachineryReleasedForFreelancerConditionsInput>) => useQuery({
        queryFn: () => $trpc.freelancerSales.getMachineryReleasedForFreelancerConditions.query(where.value),
        queryKey: ['freelancerSales', 'freelancerConditions', where],
        meta: { errorKey: 'Freigegebene Maschinen' },
      }),
      getFreelancers: () => useQuery({
        queryFn: () => $trpc.freelancerSales.getFreelancers.query(),
        queryKey: ['freelancerSales', 'users'],
        meta: { errorKey: 'Freelancer' },
      }),
      getReleaseConditionById: (id: Ref<string | undefined>) => useQuery({
        queryFn() {
          if (id.value === undefined) {
            return null
          }
          return $trpc.freelancerSales.getMachineryReleasedForFreelancerConditionById.query({ id: id.value })
        },
        queryKey: ['freelancerSales', 'getReleaseConditionById', id],
        meta: { errorKey: 'Maschinen-Konditionen' },
      }),
      getReleaseConditionsForMachineryForFreelancer: (machineryId: MaybeRef<string>) => useQuery({
        queryFn: () => $trpc.freelancerSales.getReleaseConditionsForMachineryForFreelancer.query({ machineryId: unref(machineryId) }),
        queryKey: ['freelancerSales', 'machineryConditions', machineryId],
        meta: { errorKey: 'Maschinen-Konditionen' },
      }),
      getMyOffers: () => useQuery({
        queryFn: () => $trpc.freelancerSales.getMyOffers.query(),
        queryKey: ['freelancerSales', 'offers'],
        meta: { errorKey: 'Angebote' },
      }),
      getAllOffers: () => useQuery({
        queryFn: () => $trpc.freelancerSales.getAllOffers.query(),
        queryKey: ['freelancerSales', 'offers'],
        meta: { errorKey: 'Angebote' },
      }),
      getFreelancersOfferIsSharedWith: (offerId: MaybeRef<string>) => useQuery({
        async queryFn() {
          const offer = await $trpc.freelancerSales.getFreelancersOfferIsSharedWith.query(unref(offerId))
          return offer ? offer.sharedWith : []
        },
        queryKey: ['freelancerSales', 'offerSharing', offerId],
        meta: { errorKey: 'Angebote' },
      }),
    },
    partnerSales: {
      getMachineryReleasedForPartnerConditions: (where: Ref<PartnerSalesGetMachineryReleasedForPartnerConditionsInput>) => useQuery({
        queryFn: () => $trpc.partnerSales.getMachineryReleasedForPartnerConditions.query(where.value),
        queryKey: ['partnerSales', 'partnerConditions', where],
        meta: { errorKey: 'Freigegebene Maschinen' },
      }),
      getPartners: () => useQuery({
        queryFn: () => $trpc.partnerSales.getPartners.query(),
        queryKey: ['partnerSales', 'users'],
        meta: { errorKey: 'Partner' },
      }),
      getReleaseConditionById: (id: Ref<string | undefined>) => useQuery({
        queryFn() {
          if (id.value === undefined) {
            return null
          }
          return $trpc.partnerSales.getMachineryReleasedForPartnerConditionById.query({ id: id.value })
        },
        queryKey: ['partnerSales', 'getReleaseConditionById', id],
        meta: { errorKey: 'Maschinen-Konditionen' },
      }),

      getAllOffers: () => useQuery({
        queryFn: () => $trpc.partnerSales.getAllOffers.query(),
        queryKey: ['partnerSales', 'offers'],
        meta: { errorKey: 'Angebote' },
      }),
    },
    quickSale: {
      allByPartnerSales: (where?: Where, enabled?: boolean) => useQuery({
        queryFn: () => $trpc.quickSale.getAllByPartnerSales.query({ where: where?.value }),
        queryKey: ['quickSales', 'allByPartnerSales', where],
        meta: { errorKey: 'Schnellangebote' },
        enabled,
      }),
      allByMachineryId: (machineryId: MaybeRef<string | undefined>, enabled?: boolean) => useQuery({
        queryFn: () => {
          const id = unref(machineryId)

          if (!id) {
            return []
          }

          return $trpc.quickSale.getAllByMachineryId.query({ id })
        },
        queryKey: ['quickSales', 'allByMachineryId', machineryId],
        meta: { errorKey: 'Schnellangebote' },
        enabled,
      }),
    },
    internalReservation: {
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.internalReservation.getById.query({ id: id.value })
        },
        queryKey: ['internalReservations', id],
        meta: { errorKey: 'interne Reservierung' },
      }),
      all: (where?: Where) => useQuery({
        queryFn: () => $trpc.internalReservation.getAll.query({ where: where?.value }),
        queryKey: ['internalReservations', 'all', where],
        meta: { errorKey: 'interne Reservierung' },
      }),
      getOverlappingReservations: (ids: MaybeRef<string[]>, start: MaybeRef<Date>, end: MaybeRef<Date | null>) => useQuery({
        queryFn: () => {
          const machineryIds = unref(ids)
          const startsAt = unref(start)
          const endsAt = unref(end)

          if (machineryIds.length === 0) {
            return null
          }
          return $trpc.internalReservation.getOverlappingReservations.query({ machineryIds, startsAt, endsAt })
        },
        queryKey: ['internalReservations', ids, start, end],
        meta: { errorKey: 'interne Reservierung' },
      }),
    },

    storageCustomer: {
      allExternalStorageCustomers() {
        return useQuery({
          queryFn() {
            return $trpc.externalStorage.storageCustomer.getAllExternalStorageCustomers.query()
          },
          queryKey: ['customer', 'allExternalStorageCustomers'],
          meta: { errorKey: 'Lagerkunde' },
        })
      },
      allExternalStorageSuppliers() {
        return useQuery({
          queryFn() {
            return $trpc.externalStorage.storageCustomer.getAllExternalStorageSuppliers.query()
          },
          queryKey: ['customer', 'allExternalStorageSuppliers'],
          meta: { errorKey: 'Lagerkunde' },
        })
      },
    },

    storageLoadCarrier: {
      all: (where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.externalStorage.storageLoadCarrier.getAll.query({ where: where?.value, pagination: pagination?.value }),
        queryKey: ['storageLoadCarrier', 'all', where, pagination],
        meta: { errorKey: 'Ladungsträger' },
      }),
      allOptions: (where?: Where, enabled?: Ref<boolean>) => useQuery({
        queryFn: () => $trpc.externalStorage.storageLoadCarrier.getAllOptions.query({ where: where?.value ?? {} }),
        queryKey: ['storageLoadCarrier', 'allOptions', where],
        meta: { errorKey: 'Ladungsträger' },
        enabled,
      }),
      byId: (id: Ref<string | undefined | null>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.externalStorage.storageLoadCarrier.getById.query({ id: id.value })
        },
        queryKey: ['storageLoadCarrier', 'id', id],
        meta: { errorKey: 'Ladungsträger' },
      }),
    },

    storageProject: {
      /** Gets all StorageProjects associated with a customer */
      allByCustomer(customerId: Ref<string | undefined>, enabled?: MaybeRef<boolean>) {
        return useQuery({
          queryFn: () => {
            // TODO Make `customerId` optional when external roles are introduced
            // i.e. auto determine customer from a user session
            if (!customerId.value) {
              return null
            }

            return $trpc.externalStorage.storageProject.getAllByCustomer.query({
              customerId: customerId.value,
            })
          },
          queryKey: ['externalStorageProject', 'allByCustomer', customerId],
          meta: { errorKey: 'Lagerprojekt' },
          enabled,
        })
      },

      all(where?: Where, pagination?: Ref<Pagination>) {
        return useQuery({
          queryFn: () => {
            return $trpc.externalStorage.storageProject.getAll.query({
              where: where?.value ?? {},
              pagination: pagination?.value,
            })
          },
          queryKey: ['externalStorageProject', 'all', where, pagination],
          meta: { errorKey: 'Lagerprojekt' },
        })
      },

      byId(id: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.storageProject.getById.query(id.value)
          },
          queryKey: ['externalStorageProject', 'byId', id],
          meta: { errorKey: 'Lagerprojekt' },
        })
      },
    },

    storageProjectAddress: {
      allByProjectId(projectId: string) {
        return useQuery({
          queryFn: () => {
            return $trpc.externalStorage.storageProjectAddress.getAllByProjectId.query({
              id: projectId,
            })
          },
          queryKey: ['externalStorageProjectAddress', 'allByProjectId', projectId],
          meta: { errorKey: 'Lieferadresse' },
        })
      },

      allAlternativeAddressOptions(projectId: string) {
        return useQuery({
          queryFn: () => {
            return $trpc.externalStorage.storageProjectAddress.getAllAlternativeAddressOptions.query({
              id: projectId,
            })
          },
          queryKey: ['externalStorageProjectAddress', 'allAlternativeAddressOptions', projectId],
          meta: { errorKey: 'Lieferadresse' },
        })
      },
    },

    storageProduct: {
      /** Gets all StorageProducts associated with a customer */
      all(customerId: Ref<string | undefined>, where?: Where, pagination?: Ref<Pagination>) {
        return useQuery({
          queryFn: () => {
            // TODO Make `customerId` optional when external roles are introduced
            // i.e. auto determine customer from a user session
            if (!customerId.value) {
              return null
            }

            return $trpc.externalStorage.storageProduct.getAll.query({
              customerId: customerId.value,
              where: where?.value ?? {},
              pagination: pagination?.value,
            })
          },
          queryKey: ['externalStorageProduct', 'all', customerId, where, pagination],
          meta: { errorKey: 'Artikelstamm' },
        })
      },

      /**
       * Composable for getting `StorageProduct` by ID
       * @returns data will be `null` if `id` is nullish
       */
      byId(id: Ref<string | undefined>) {
        const $trpc = useNuxtApp().$trpc

        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.storageProduct.getById.query(id.value)
          },
          queryKey: ['externalStorageProduct', 'one', id],
          meta: { errorKey: 'Artikelstamm' },
        })
      },
    },
    storageCategory: {
      allByCustomer: (customerId: Ref<string | undefined>, enabled?: boolean) => useQuery({
        queryFn: () => {
          if (!customerId.value) {
            return []
          }

          return $trpc.externalStorage.storageCategory.getAllByCustomerId.query(customerId.value)
        },
        queryKey: ['storageCategory', 'all', customerId],
        meta: { errorKey: 'Lagerkategorie' },
        enabled,
      }),
    },
    storagePosition: {
      /**
       * Gets all StoragePositions associated with a customer
       * Gross weights are not included in where parameter as it needs to be computed/processed before queried from the database
       */
      all(
        customerId: Ref<string | undefined>,
        minGrossWeightInKg: Ref<number | null>,
        maxGrossWeightInKg: Ref<number | null>,
        retrievedAtRange: Ref<[number, number] | null>,
        sortByStoredAt: Ref<boolean>,
        where?: Where,
        pagination?: Ref<Pagination>,
      ) {
        return useQuery({
          queryFn() {
            // TODO Make `customerId` optional when external roles are introduced
            // i.e. auto determine customer from a user session
            if (!customerId.value) {
              return null
            }

            const orderBy: Prisma.StoragePositionOrderByWithRelationInput[] | undefined = sortByStoredAt.value
              ? [
                  { storagePositionSet: { storedAt: 'desc' } },
                ]
              : undefined

            return $trpc.externalStorage.storagePosition.getAll.query({
              customerId: customerId.value,
              where: where?.value ?? {},
              pagination: pagination?.value,
              minGrossWeightInKg: minGrossWeightInKg.value,
              maxGrossWeightInKg: maxGrossWeightInKg.value,
              retrievedAtRange: retrievedAtRange.value,
              orderBy,
            })
          },
          queryKey: ['externalStoragePosition', 'all', customerId, minGrossWeightInKg, maxGrossWeightInKg, retrievedAtRange, sortByStoredAt, where, pagination],
          meta: { errorKey: 'Lagerposition' },
        })
      },

      count(customerId: Ref<string | undefined>, where?: Where) {
        return useQuery({
          queryFn() {
            if (!customerId.value) {
              return null
            }

            return $trpc.externalStorage.storagePosition.getCount.query({
              customerId: customerId.value,
              where: where?.value ?? {},
            })
          },
          queryKey: ['externalStoragePosition', 'count', customerId, where],
          meta: { errorKey: 'Lagerposition' },
        })
      },

      toApproveCounts: (enabled: boolean) => useQuery({
        queryFn: () => $trpc.externalStorage.storagePosition.getToApproveCounts.query(),
        queryKey: ['externalStoragePosition', 'toApproveCounts'],
        enabled,
      }),

      /**
       * Composable for getting `StoragePosition` by ID
       * @returns data will be `null` if `id` is nullish
       */
      byId(id: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.storagePosition.getById.query(id.value)
          },
          queryKey: ['externalStoragePosition', 'one', id],
          meta: { errorKey: 'Lagerposition' },
        })
      },

      statusById(id: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.storagePosition.getStatusById.query(id.value)
          },
          queryKey: ['externalStoragePosition', 'status', id],
          meta: { errorKey: 'Lagerposition' },
        })
      },

      historyById(id: Ref<string | undefined>, enabled?: boolean) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.storagePosition.getHistoryById.query(id.value)
          },
          queryKey: ['externalStoragePosition', 'history', id],
          meta: { errorKey: 'Lagerposition' },
          enabled,
        })
      },
    },

    storagePositionSet: {
      all: (customerId: Ref<string | undefined>, where?: Where) => useQuery({
        queryFn() {
          if (!customerId.value) {
            return null
          }

          return $trpc.externalStorage.storagePositionSet.getAll.query({
            customerId: customerId.value,
            where: where?.value ?? {},
          })
        },
        queryKey: ['externalStoragePositionSet', 'all', customerId, where],
        meta: { errorKey: 'Lagerpositionen' },
      }),
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn() {
          if (!id.value) {
            return null
          }

          return $trpc.externalStorage.storagePositionSet.getById
            .query(id.value)
        },
        queryKey: ['externalStoragePositionSet', id],
        meta: { errorKey: 'Lagerpositionen' },
      }),
      historyById(id: Ref<string | undefined>, enabled?: boolean) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.storagePositionSet.getHistoryById.query(id.value)
          },
          queryKey: ['externalStoragePositionSet', 'history', id],
          meta: { errorKey: 'Lagerpositionen' },
          enabled,
        })
      },
      overviewByCustomerId(customerId: Ref<string | undefined>, shouldCheckRetrieved: Ref<boolean>) {
        return useQuery({
          queryFn() {
            if (!customerId.value) {
              return null
            }

            return $trpc.externalStorage.storagePositionSet.getOverviewByCustomerId.query({ customerId: customerId.value, shouldCheckRetrieved: shouldCheckRetrieved.value })
          },
          queryKey: ['externalStoragePositionSet', 'overview', customerId, shouldCheckRetrieved],
          meta: { errorKey: 'Lagerpositionen' },
        })
      },
      trailerLicensePlateOptions(id: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.storagePositionSet.getTrailerLicensePlateOptions.query(id.value)
          },
          queryKey: ['externalStoragePositionSet', 'trailerLicensePlateOptions', id],
          meta: { errorKey: 'Lieferfahrzeuge' },
        })
      },
      defaultDeliveryVehicleIdById(id: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.storagePositionSet.getDefaultDeliveryVehicleIdById.query(id.value)
          },
          queryKey: ['externalStoragePositionSet', 'defaultDeliveryVehicleIdById', id],
          meta: { errorKey: 'Lieferfahrzeuge' },
        })
      },
    },

    inboundLogisticsTask: {
      allByCustomer: (customerId: Ref<string | undefined>, where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => {
          if (!customerId.value) {
            return null
          }

          return $trpc.externalStorage.inboundLogisticsTask.getAll.query({
            customerId: customerId.value,
            where: where?.value ?? {},
            pagination: pagination?.value,
          })
        },
        queryKey: ['inboundLogisticsTask', 'allByCustomer', customerId, where, pagination],
        meta: { errorKey: 'Einlagerung' },
      }),
      all: (customerId: Ref<string | undefined>, where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.externalStorage.inboundLogisticsTask.getAll.query({
          customerId: customerId.value,
          where: where?.value ?? {},
          pagination: pagination?.value,
        }),
        queryKey: ['inboundLogisticsTask', 'all', customerId, where, pagination],
        meta: { errorKey: 'Einlagerung' },
      }),
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }
          return $trpc.externalStorage.inboundLogisticsTask.getById.query({ id: id.value })
        },
        queryKey: ['inboundLogisticsTask', 'one', id],
        meta: { errorKey: 'Einlagerung' },
      }),
      toStoreCounts: (enabled: boolean) => useQuery({
        queryFn: () => $trpc.externalStorage.inboundLogisticsTask.getToStoreCounts.query(),
        queryKey: ['inboundLogisticsTask', 'toStoreCounts'],
        enabled,
      }),
      getOnSiteLoadingOptions: () => useQuery({
        queryFn: () => $trpc.externalStorage.inboundLogisticsTask.getOnSiteLoadingOptions.query(),
        queryKey: ['loadingOptions'],
        meta: { errorKey: 'Belademöglichkeiten' },
      }),
      allForDrivers: (vehicleId: Ref<string | undefined>, deliveryAt: Ref<{ gte: Date, lte: Date }>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          if (!vehicleId.value) {
            return null
          }
          return $trpc.externalStorage.inboundLogisticsTask.driver.getAll.query({ vehicleId: vehicleId.value, deliveryAt: deliveryAt.value })
        },
        queryKey: ['inboundLogisticsTask', 'allForDrivers', vehicleId, deliveryAt],
        meta: { errorKey: 'Einlagerung' },
        enabled,
      }),
      getAllNotDoneForDriver: (vehicleId: Ref<string | undefined>, deliveryAt: Ref<{ gte: Date, lte: Date }>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          if (!vehicleId.value) {
            return null
          }
          return $trpc.externalStorage.inboundLogisticsTask.driver.getAllNotDone.query({ vehicleId: vehicleId.value, deliveryAt: deliveryAt.value })
        },
        queryKey: ['inboundLogisticsTask', 'allNotDoneForDriver', vehicleId, deliveryAt],
        meta: { errorKey: 'Einlagerung' },
        enabled,
      }),
      byIdForDrivers: (id: Ref<string | undefined>) => useQuery({
        queryFn() {
          if (!id.value) {
            return null
          }
          return $trpc.externalStorage.inboundLogisticsTask.driver.getById.query({ id: id.value })
        },
        queryKey: ['inboundLogisticsTask', 'byIdForDrivers', id],
        meta: { errorKey: 'Einlagerung' },
      }),
    },

    partialStoragePosition: {
      allByCustomer: (customerId: Ref<string | undefined>, where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn() {
          if (!customerId.value) {
            return null
          }

          return $trpc.externalStorage.partialStoragePosition.getAll.query({
            customerId: customerId.value,
            where: where?.value ?? {},
            pagination: pagination?.value,
          })
        },
        queryKey: ['externalStoragePartialStoragePosition', 'allByCustomer', customerId, where, pagination],
        meta: { errorKey: 'Lagerposition' },
      }),

      allByCustomerAndDeliveryDate(customerId: Ref<string>, deliveryAt: Ref<Date | undefined>) {
        return useQuery({
          queryFn() {
            if (!deliveryAt.value) {
              return null
            }

            return $trpc.externalStorage.partialStoragePosition.getAll.query({
              customerId: customerId.value,
              where: {
                deliveryAt: { gte: deliveryAt.value, lt: addDaysDstSafe(deliveryAt.value, 1) },
                outboundLogisticsTaskId: null,
              },
            })
          },
          queryKey: ['externalStoragePartialStoragePosition', 'allByCustomerAndDeliveryDate', customerId, deliveryAt],
          meta: { errorKey: 'Lagerposition' },
        })
      },

      byId: (id: Ref<string | undefined>, enabled?: boolean) => useQuery({
        queryFn() {
          if (!id.value) {
            return null
          }

          return $trpc.externalStorage.partialStoragePosition.getById.query({ id: id.value })
        },
        queryKey: ['externalStoragePartialStoragePosition', 'byId', id],
        meta: { errorKey: 'Lagerposition' },
        enabled,
      }),

      trailerLicensePlateOptions(id: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.partialStoragePosition.getTrailerLicensePlateOptions.query(id.value)
          },
          queryKey: ['externalStoragePartialStoragePosition', 'trailerLicensePlateOptions', id],
          meta: { errorKey: 'Lieferfahrzeuge' },
        })
      },

      defaultDeliveryVehicleIdById(id: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.partialStoragePosition.getDefaultDeliveryVehicleIdById.query(id.value)
          },
          queryKey: ['externalStoragePartialStoragePosition', 'defaultDeliveryVehicleIdById', id],
          meta: { errorKey: 'Lieferfahrzeuge' },
        })
      },

      carrierNameAndLicensePlateOptions(storagePositionId: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!storagePositionId.value) {
              return null
            }

            return $trpc.externalStorage.partialStoragePosition.getCarrierNameAndLicensePlateOptions.query(storagePositionId.value)
          },
          queryKey: ['externalStoragePartialStoragePosition', 'carrierNameAndLicensePlateOptions', storagePositionId],
          meta: { errorKey: 'Spedition' },
        })
      },
    },

    outboundLogisticsTask: {
      allByCustomer: (customerId: Ref<string | undefined>, where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn() {
          if (!customerId.value) {
            return null
          }

          return $trpc.externalStorage.outboundLogisticsTask.getAll.query({
            customerId: customerId.value,
            where: where?.value ?? {},
            pagination: pagination?.value,
          })
        },
        queryKey: ['externalStorageOutboundLogisticsTask', 'allByCustomer', customerId, where, pagination],
        meta: { errorKey: 'Auslagerung' },
      }),

      all: (customerId: Ref<string | undefined>, where?: Where, pagination?: Ref<Pagination>) => useQuery({
        queryFn: () => $trpc.externalStorage.outboundLogisticsTask.getAll.query({
          customerId: customerId.value,
          where: where?.value ?? {},
          pagination: pagination?.value,
        }),
        queryKey: ['externalStorageOutboundLogisticsTask', 'all', customerId, where, pagination],
        meta: { errorKey: 'Auslagerung' },
      }),

      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn() {
          if (!id.value) {
            return null
          }
          return $trpc.externalStorage.outboundLogisticsTask.getById.query({ id: id.value })
        },
        queryKey: ['externalStorageOutboundLogisticsTask', 'byId', id],
        meta: { errorKey: 'Auslagerung' },
      }),

      toLoadCounts: (enabled: boolean) => useQuery({
        queryFn: () => $trpc.externalStorage.outboundLogisticsTask.getToLoadCounts.query(),
        queryKey: ['externalStorageOutboundLogisticsTask', 'toLoadCounts'],
        enabled,
      }),

      allForDrivers: (vehicleId: Ref<string | undefined>, deliveryAt: Ref<{ gte: Date, lte: Date }>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          if (!vehicleId.value) {
            return null
          }
          return $trpc.externalStorage.outboundLogisticsTask.driver.getAll.query({ vehicleId: vehicleId.value, deliveryAt: deliveryAt.value })
        },
        queryKey: ['outboundLogisticsTask', 'allForDrivers', vehicleId, deliveryAt],
        meta: { errorKey: 'Auslagerung' },
        enabled,
      }),

      getAllNotDoneForDriver: (vehicleId: Ref<string | undefined>, deliveryAt: Ref<{ gte: Date, lte: Date }>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          if (!vehicleId.value) {
            return null
          }
          return $trpc.externalStorage.outboundLogisticsTask.driver.getAllNotDone.query({ vehicleId: vehicleId.value, deliveryAt: deliveryAt.value })
        },
        queryKey: ['outboundLogisticsTask', 'allNotDoneForDriver', vehicleId, deliveryAt],
        meta: { errorKey: 'Einlagerung' },
        enabled,
      }),

      byIdForDrivers: (id: Ref<string | undefined>) => useQuery({
        queryFn() {
          if (!id.value) {
            return null
          }
          return $trpc.externalStorage.outboundLogisticsTask.driver.getById.query({ id: id.value })
        },
        queryKey: ['outboundLogisticsTask', 'byIdForDrivers', id],
        meta: { errorKey: 'Auslagerung' },
      }),
    },

    outboundLogisticsTaskDelivery: {
      allByTaskId(id: Ref<string | undefined>) {
        return useQuery({
          queryFn: () => {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.outboundLogisticsTaskDelivery.getAll.query({
              where: {
                outboundLogisticsTaskId: id.value,
              },
            })
          },
          queryKey: ['outboundLogisticsTaskDelivery', 'allByTaskId', id],
          meta: { errorKey: 'Auslagerung' },
        })
      },
    },

    externalStorageDeliveryVehicleAllocation: {
      all(where?: Where, pagination?: Ref<Pagination>) {
        return useQuery({
          queryFn: () => $trpc.externalStorage.deliveryVehicleAllocation.getAll.query({
            where: where?.value ?? {},
            pagination: pagination?.value,
          }),
          queryKey: ['externalStorageDeliveryVehicleAllocation', 'all', where, pagination],
          meta: { errorKey: 'Lagerdispo' },
        })
      },

      byId(id: Ref<string | undefined>) {
        return useQuery({
          queryFn() {
            if (!id.value) {
              return null
            }

            return $trpc.externalStorage.deliveryVehicleAllocation.getById.query(id.value)
          },
          queryKey: ['externalStorageDeliveryVehicleAllocation', 'byId', id],
          meta: { errorKey: 'Lagerdispo' },
        })
      },

      vehicleOptions() {
        return useQuery({
          queryFn: () => $trpc.externalStorage.deliveryVehicleAllocation.getVehicleOptions.query(),
          queryKey: ['externalStorageDeliveryVehicleAllocation', 'vehicleOptions'],
          meta: { errorKey: 'Lagerdispo' },
        })
      },
    },

    externalStorageRides: {
      relevantOffloadedPositions(projectId: Ref<string | undefined>, date: Ref<Date | null>) {
        return useQuery({
          queryFn() {
            if (!projectId.value || !date.value) {
              return null
            }

            return $trpc.externalStorage.storageRides.getRelevantOffloadedPositions.query({
              projectId: projectId.value,
              date: date.value,
            })
          },
          queryKey: ['externalStorageRides', 'relevantOffloadedPositions', projectId, date],
          meta: { errorKey: 'Lagerfahrten' },
        })
      },
      relevantDeliveredPartialPositions(projectId: Ref<string | undefined>, date: Ref<Date | null>) {
        return useQuery({
          queryFn() {
            if (!projectId.value || !date.value) {
              return null
            }

            return $trpc.externalStorage.storageRides.getRelevantDeliveredPartialPositions.query({
              projectId: projectId.value,
              date: date.value,
            })
          },
          queryKey: ['externalStorageRides', 'relevantLoadedPartialPositions', projectId, date],
          meta: { errorKey: 'Lagerfahrten' },
        })
      },
      rides(projectId: Ref<string | undefined>, date: Ref<Date | null>) {
        return useQuery({
          queryFn() {
            if (!projectId.value || !date.value) {
              return null
            }

            return $trpc.externalStorage.storageRides.getRides.query({
              projectId: projectId.value,
              date: date.value,
            })
          },
          queryKey: ['externalStorageRides', 'rides', projectId, date],
          meta: { errorKey: 'Lagerfahrten' },
        })
      },
      ridesCommentCounts(ids: Ref<string[] | undefined>) {
        return useQuery({
          queryFn() {
            if (!ids.value) {
              return null
            }

            return $trpc.externalStorage.storageRides.getRidesCommentCounts.query(ids.value)
          },
          queryKey: ['comment', 'externalStorageRides', 'getRidesCommentsCount', ids],
          meta: { errorKey: 'Lagerfahrten' },
        })
      },
    },

    externalStorageInvoicingEvent: {
      allByStoragePositionId(storagePositionId: Ref<string | undefined>, pagination?: Ref<Pagination>, where?: Where, sorters?: MaybeRef<Record<string, 'asc' | 'desc'>[]>) {
        return useQuery({
          queryFn() {
            if (!storagePositionId.value) {
              return null
            }

            return $trpc.externalStorage.invoicingEvent.getAllByStoragePosition.query({
              storagePositionId: storagePositionId.value,
              pagination: pagination?.value,
              where: where?.value ?? {},
              sorters: unref(sorters),
            })
          },
          queryKey: ['externalStorageInvoicingEvent', 'allByStoragePositionId', storagePositionId, pagination, where, sorters],
        })
      },
      totalUsedStorageSpaceArea(where: Ref<_Where | undefined>, enabled: boolean) {
        return useQuery({
          queryFn() {
            if (!where.value) {
              return null
            }

            return $trpc.externalStorage.invoicingEvent.getTotalUsedStorageSpaceArea.query({ where: where?.value ?? {} })
          },
          queryKey: ['externalStorageInvoicingEvent', 'totalUsedStorageSpaceArea', where, enabled],
          enabled,
        })
      },
    },

    storageUser: {
      all: (where?: Where) => useQuery({
        queryFn: () => $trpc.externalStorage.storageUser.getAll.query({ where: where?.value ?? {} }),
        queryKey: ['users', 'storage', 'all', where],
        meta: { errorKey: 'Lagernutzer' },
      }),
      byEmail: (email: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!email.value) {
            return null
          }

          return $trpc.externalStorage.storageUser.getByEmail.query({ email: email.value })
        },
        queryKey: ['users', 'storage', email],
        meta: { errorKey: 'Lagernutzer' },
      }),
    },
    positionTemplateBundle: {
      allForOverview: (where?: Where) => useQuery({
        queryFn: () => $trpc.positionTemplateBundle.getAllForOverview.query({ where: where?.value }),
        queryKey: ['positionTemplateBundle', 'all', 'overview', where],
        meta: { errorKey: 'Positionsvorlage' },
      }),
      allForOffer: (where?: Where) => useQuery({
        queryFn: () => $trpc.positionTemplateBundle.getAllForOffer.query({ where: where?.value }),
        queryKey: ['positionTemplateBundle', 'all', 'offer', where],
        meta: { errorKey: 'Positionsvorlage' },
      }),
      byId: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.positionTemplateBundle.getById.query({ id: id.value })
        },
        queryKey: ['positionTemplateBundle', id],
        meta: { errorKey: 'Positionsvorlage' },
      }),
    },
    serviceProjectTimeTrackingEntry: {
      allByOfferId: (id: Ref<string | undefined>, where: Where) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return []
          }

          return $trpc.serviceProjectTimeTrackingEntry.getAllByOfferId.query({ id: id.value, where: where.value })
        },
        queryKey: ['serviceProjectTimeTrackingEntry', 'allByOfferId', id, where],
        meta: { errorKey: 'Tracking-Eintrag' },
      }),
      hoursByOfferId: (id: MaybeRef<string | undefined>, enabled: Ref<boolean>) => useQuery({
        queryFn: () => {
          const offerId = unref(id)

          if (!offerId) {
            return null
          }

          return $trpc.serviceProjectTimeTrackingEntry.getHoursByOfferId.query({ id: offerId })
        },
        queryKey: ['serviceProjectTimeTrackingEntry', 'hoursByOfferId', id, enabled],
        meta: { errorKey: 'Tracking-Stunden' },
        enabled,
      }),
      weekReportsByOfferCuid: (cuid: MaybeRef<string>) => useQuery({
        queryFn: () => $trpc.serviceProjectTimeTrackingEntry.getWeekReportsByOfferCuid.query({ cuid: unref(cuid) }),
        queryKey: ['serviceProjectTimeTrackingEntry', 'weekReportsByOfferCuid', cuid],
        meta: { errorKey: 'Wochenberichte' },
      }),
      public: {
        weekReportsByOfferCuid: (cuid: MaybeRef<string>) => useQuery({
          queryFn: () => $trpc.serviceProjectTimeTrackingEntry.public.getWeekReportsByOfferCuid.query({ cuid: unref(cuid) }),
          queryKey: ['serviceProjectTimeTrackingEntry', 'public', 'weekReportsByOfferCuid', cuid],
          meta: { errorKey: 'Tagesberichte' },
        }),
      },
    },
    serviceProjectDailyReport: {
      allByOfferId: (id: Ref<string | undefined>, where: Where) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return []
          }

          return $trpc.serviceProjectDailyReport.getAllByOfferId.query({ id: id.value, where: where.value })
        },
        queryKey: ['serviceProjectDailyReport', 'allByOfferId', id, where],
        meta: { errorKey: 'Tagesberichte' },
      }),
      public: {
        photosByOfferId: (id: Ref<string | undefined>) => useQuery({
          queryFn: () => {
            if (!id.value) {
              return []
            }

            return $trpc.serviceProjectDailyReport.public.getPhotosByOfferId.query(id.value)
          },
          queryKey: ['serviceProjectDailyReport', 'photosByOfferIdPublic', id],
          meta: { errorKey: 'Tagesberichte' },
        }),
      },
    },
    serviceProjectInterimReport: {
      allByOfferId: (id: Ref<string | undefined>, where: Where) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return []
          }

          return $trpc.serviceProjectInterimReport.getAllByOfferId.query({ id: id.value, where: where.value })
        },
        queryKey: ['serviceProjectInterimReport', 'allByOfferId', id, where],
        meta: { errorKey: 'Zwischenberichte' },
      }),
    },
    rentalPricing: {
      allMachineryTypeBasePrices: (pagination: Ref<Pagination>, where?: Where) => useQuery({
        queryFn: () => $trpc.shop.rentalPricing.getMachineryTypeBasePrices.query({
          pagination: pagination.value,
          where: where?.value,
        }),
        queryKey: ['rentalPricing', 'allMachineryTypeBasePrices', pagination, where],
        meta: { errorKey: 'Vermietung' },
      }),
      allMachineryTypeOptions: (search?: Ref<string | undefined>) => useQuery({
        queryFn: () => $trpc.shop.rentalPricing.getMachineryTypeOptions.query({
          search: unref(search),
        }),
        queryKey: ['rentalPricing', 'allMachineryTypeOptions', search],
        meta: { errorKey: 'Vermietung' },
      }),
      allMachineryBasePrices: (pagination: Ref<Pagination>, where?: Where) => useQuery({
        queryFn: () => $trpc.shop.rentalPricing.getMachineryBasePrices.query({
          pagination: pagination.value,
          where: where?.value,
        }),
        queryKey: ['rentalPricing', 'allMachineryBasePrices', pagination, where],
        meta: { errorKey: 'Vermietung' },
      }),
      allMachineryOptions: (search?: Ref<string | undefined>) => useQuery({
        queryFn: () => $trpc.shop.rentalPricing.getMachineryOptions.query({
          search: unref(search),
        }),
        queryKey: ['rentalPricing', 'allMachineryOptions', search],
        meta: { errorKey: 'Vermietung' },
      }),
      basePriceById: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.shop.rentalPricing.getBasePriceById.query(id.value)
        },
        queryKey: ['rentalPricing', 'basePriceById', id],
        meta: { errorKey: 'Vermietung' },
      }),
      pricesOfMachineryType: (id: Ref<string>) => useQuery({
        queryFn: () => $trpc.shop.rentalPricing.getPricesOfMachineryType.query(id.value),
        queryKey: ['rentalPricing', 'pricesOfMachineryType', id],
        meta: { errorKey: 'Vermietung' },
      }),
      machineryBasePriceByMachineryId: (id: Ref<string>) => useQuery({
        queryFn: () => $trpc.shop.rentalPricing.getMachineryBasePriceByMachineryId.query(id.value),
        queryKey: ['rentalPricing', 'machineryBasePriceByMachineryId', id],
        meta: { errorKey: 'Vermietung' },
      }),
      pricesOfMachinery: (id: Ref<string>) => useQuery({
        queryFn: () => $trpc.shop.rentalPricing.getPricesOfMachinery.query(id.value),
        queryKey: ['rentalPricing', 'pricesOfMachinery', id],
        meta: { errorKey: 'Vermietung' },
      }),
      priceModifierById: (id: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          if (!id.value) {
            return null
          }

          return $trpc.shop.rentalPricing.getPriceModifierById.query(id.value)
        },
        queryKey: ['rentalPricing', 'priceModifierById', id],
        meta: { errorKey: 'Vermietung' },
      }),
    },
    rentalPause: {
      allByOfferId: (id: MaybeRef<string | undefined>, showDeleted?: Ref<boolean>, selectedDateRange?: Ref<[number, number] | null>) => useQuery({
        queryFn: () => {
          const offerId = unref(id)
          const [rangeStart, rangeEnd] = selectedDateRange?.value ?? [undefined, undefined]
          if (!offerId) {
            return []
          }

          return $trpc.rentalPause.getAllByOfferId.query({
            offerId,
            where: {
              deletedAt: showDeleted?.value ? { not: null } : null,
              startDate: rangeStart ? { gte: new Date(rangeStart) } : undefined,
              endDate: rangeEnd ? { lte: new Date(rangeEnd) } : undefined,
            },
          })
        },
        queryKey: ['rentalPause', 'allByOfferId', id, showDeleted, selectedDateRange],
        meta: { errorKey: 'Mietpausen' },
      }),
    },
    commandPalette: {
      offers: (query: Ref<string | undefined>) => useQuery({
        queryFn: () => $trpc.commandPalette.offers.query(unref(query)),
        enabled: () => !!unref(query),
        queryKey: ['commandPalette', 'offers', query],
      }),
    },
    address: {
      byQuery: (input: Ref<string | undefined>) => useQuery({
        queryFn: () => {
          const query = unref(input)
          if (!query) {
            return
          }
          return $trpc.address.byQuery.query(query)
        },
        enabled: () => !!unref(input),
        queryKey: ['address', 'byQuery', input],
        meta: { errorKey: 'Adresse' },
      }),
      byLatLng: (input: Ref<{ lat: number, lng: number } | undefined>) => useQuery({
        queryFn: () => {
          const latLng = unref(input)
          if (!latLng) {
            return
          }
          return $trpc.address.byLatLng.query(latLng)
        },
        enabled: () => !!unref(input),
        queryKey: ['address', 'byLatLng', input],
        meta: { errorKey: 'Adresse' },
      }),
    },
    groupedPhoto: {
      byGroup: (group: GroupedPhotoGroup) => useQuery({
        queryFn: () => $trpc.groupedPhoto.byGroup.query({ group }),
        queryKey: ['groupedPhoto', 'byGroup', group],
        meta: { errorKey: 'Gruppenfotos' },
      }),
    },
    externalSalesMarketplace: {
      getAll: (enabled?: MaybeRef<boolean>) => useQuery({
        queryFn: () => $trpc.externalSalesMarketplace.getAll.query(),
        queryKey: ['externalSalesMarketplace', 'getAll'],
        meta: { errorKey: 'Externe Markplätze' },
        enabled,
      }),
    },
  } as const
}
